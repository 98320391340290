import { useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
// eslint-disable-next-line no-unused-vars
import buildTipsTableData from "layouts/tips/data/tipsTableData";
import { useNavigate } from "react-router-dom";

// MS
import service from "../../services/apiMS/index";

function Tables() {
  // const { columns, rows } = authorsTableData();
  // eslint-disable-next-line no-unused-vars
  const [tipsTableData, setTipsTableData] = useState({
    columns: [],
    rows: [],
  });
  const [pageCount, setPageCount] = useState(0);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectTipDelete, setSelectTipDelete] = useState();
  const [deleteResponse, setDeleteResponse] = useState();
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();

  const deleteTipHandler = (idTip) => {
    setSelectTipDelete(idTip);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const fetchData = useCallback(
    async ({ pageIndex }) => {
      const userDataString = localStorage.getItem("userData");
      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setDataProcessing(true);
      const tipsResponse = await service.getTips({ userData, pageIndex: pageIndex + 1 });

      if (!tipsResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (tipsResponse.ok) {
        const tipsData = await tipsResponse.json();
        const newTipsTableData = buildTipsTableData(tipsData.data, deleteTipHandler);
        setTipsTableData(newTipsTableData);
        setPageCount(tipsData.last_page);
      }
      setDataProcessing(false);
    },
    [navigate]
  );

  const handleConfirmDelete = async () => {
    const userDataString = localStorage.getItem("userData");

    if (!userDataString) {
      navigate("/authentication/sign-in");
      return;
    }

    const userData = JSON.parse(userDataString);

    const deleteTipResponse = await service.deleteTip({ userData, tipId: selectTipDelete });

    if (!deleteTipResponse.ok) {
      navigate("/authentication/sign-in");
    }

    if (deleteTipResponse.ok) {
      setOpenModal(false);
      setOpen(true);
      setDeleteResponse(true);
      fetchData({ pageIndex: 0 });
    } else {
      setDeleteResponse(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <MDTypography variant="h6" color="white">
                  Tips
                </MDTypography>
              </MDBox>
              {openModal && (
                <Dialog
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Confirmación</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      ¿Está seguro de eliminar el tip?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      Cancelar
                    </Button>
                    <Button onClick={handleConfirmDelete} autoFocus>
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              {deleteResponse === true && (
                <Collapse in={open}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Eliminación exitosa
                  </Alert>
                </Collapse>
              )}
              {deleteResponse === false && (
                <Collapse in={open}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    severity="error"
                    sx={{ mb: 2 }}
                  >
                    Ocurrió un error
                  </Alert>
                </Collapse>
              )}
              <MDBox mt={2} py={1} mx={2} display="flex" flexDirection="row-reverse">
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="success"
                  onClick={() => navigate("/newtip")}
                >
                  <Icon>add</Icon>&nbsp; Crear tip
                </MDButton>
              </MDBox>

              <MDBox>
                {tipsTableData && (
                  <DataTable
                    canSearch={false}
                    canFilterDate={false}
                    canExport={false}
                    table={{
                      columns: tipsTableData.columns,
                      rows: tipsTableData.rows,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    fetchData={fetchData}
                    pageCount={pageCount}
                    noEndBorder
                  />
                )}
                <MDBox pb={6}>
                  <div align="center">
                    {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : ""}
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
