import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import tututuLogo from "assets/images/logos/tututu_logo.png";
import tupolizaLogo from "assets/images/logos/tu_poliza_logo.png";

// MS
import service from "../../../services/apiMS/index";

function Basic() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loginProcessing, setLoginProcessing] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorUser, setLoginErrorUser] = useState(false);
  const [loginErrorPassword, setLoginErrorPassword] = useState(false);

  const navigate = useNavigate();

  const handleUserChange = (event) => {
    if (event.target.value) {
      setLoginError(false);
      setLoginErrorUser(false);
    }
    setUser(event.target.value);
  };
  const handlePasswordChange = (event) => {
    if (event.target.value) {
      setLoginError(false);
      setLoginErrorPassword(false);
    }
    setPassword(event.target.value);
  };

  const handleLoginClick = async () => {
    if (!user) {
      setLoginErrorUser(true);
    }
    if (!password) {
      setLoginErrorPassword(true);
    }
    if (!user || !password) {
      return;
    }
    setLoginProcessing(true);
    const loginResponse = await service.login(user, password);

    if (loginResponse.ok) {
      const userData = await loginResponse.json();
      localStorage.setItem("userData", JSON.stringify(userData));
      navigate("/");
    } else {
      setLoginError(true);
    }

    setLoginProcessing(false);
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13 && loginProcessing === false) {
      handleLoginClick();
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={1}
          mb={1}
          textAlign="center"
        >
          <MDBox component="img" src={tupolizaLogo} alt="40%" width="50%" mr={2} />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {loginErrorUser && <Alert severity="error">Usuario no puede ser vacío.</Alert>}
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="user"
                label="Usuario"
                fullWidth
                value={user}
                required
                onChange={handleUserChange}
                autoFocus
              />
            </MDBox>
            {loginErrorPassword && <Alert severity="error">Contraseña no puede ser vacía.</Alert>}
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Contraseña"
                fullWidth
                value={password}
                required
                onChange={handlePasswordChange}
                onKeyPress={handleKeypress}
              />
            </MDBox>
            {loginError && <Alert severity="error">Usuario o contraseña incorrecto.</Alert>}

            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={loginProcessing}
                onClick={handleLoginClick}
                variant="gradient"
                color="success"
                fullWidth
              >
                {loginProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : "ingresar"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                ¿Olvidaste tu contraseña?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/password-recovery"
                  variant="button"
                  color="success"
                  fontWeight="medium"
                  textGradient
                >
                  Recuperar contraseña
                </MDTypography>
                <MDTypography>
                  <MDBox component="img" src={tututuLogo} alt="20%" width="30%" />
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
