export default (values) => {
  const errors = {};

  if (!values.insurance_company.name) {
    errors.insurance_company_name = "La aseguradora es requerida";
  }

  if (!values.name) {
    errors.name = "El nombre es requerido";
  }

  if (!values.number) {
    errors.number = "El número es requerido";
  }

  return errors;
};
