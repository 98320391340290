import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import moment from "moment";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function Insurance({
  companyName,
  insuranceId,
  vehicleId,
  vehicleBrand,
  vehicleModel,
  vehicleEngine,
  policy,
  status,
  update,
  noGutter,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      flexDirection="row"
      component="li"
      display="flex"
      justifyContent="space-between"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {companyName}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Póliza:
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="capitalize">
              {policy}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Estado:
            <MDTypography
              variant="caption"
              fontWeight="medium"
              ml={1}
              color={(() => {
                switch (status) {
                  case "pending":
                    return "warning";
                  case "user_error":
                    return "error";
                  default:
                    return "success";
                }
              })()}
            >
              {status}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Fecha de actualización:
            <MDTypography ml={1} variant="caption" fontWeight="medium">
              {moment(update).format("DD/MM/YYYY-HH:MM")}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Vehículo:
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="capitalize">
              Marca: {vehicleBrand}
            </MDTypography>
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="capitalize">
              Modelo: {vehicleModel}
            </MDTypography>
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="capitalize">
              Patente: {vehicleEngine}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox pt={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={2}>
              <MuiLink href={`/insurance/${insuranceId}`}>
                <MDButton variant="outlined" color="secondary" size="small">
                  Detalle Póliza
                </MDButton>
              </MuiLink>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <MuiLink href={`/vehicle/${vehicleId}`}>
                <MDButton variant="outlined" color="secondary" size="small">
                  Detalle Vehículo
                </MDButton>
              </MuiLink>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
Insurance.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Insurance.propTypes = {
  companyName: PropTypes.string.isRequired,
  insuranceId: PropTypes.string.isRequired,
  vehicleId: PropTypes.string.isRequired,
  vehicleBrand: PropTypes.string.isRequired,
  vehicleModel: PropTypes.string.isRequired,
  vehicleEngine: PropTypes.string.isRequired,
  policy: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  update: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Insurance;
