import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import buildCompaniesTableData from "layouts/dashboard/components/Projects/data/companiesTableData";

// MS
import service from "../../../../services/apiMS/index";

function ReportCompanies() {
  const [dataProcessing, setDataProcessing] = useState(false);
  const [reportCompanies, setReportCompanies] = useState({
    columns: [],
    rows: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const getReportCompanies = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setDataProcessing(true);
      const reportCompaniesResponse = await service.getReportCompanies({ userData });

      if (!reportCompaniesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (reportCompaniesResponse.ok) {
        const companiesReportData = await reportCompaniesResponse.json();
        const companiesToArray = Object.values(companiesReportData);
        const companiesTableData = buildCompaniesTableData(companiesToArray);
        setReportCompanies(companiesTableData);
      }
      setDataProcessing(false);
    };
    getReportCompanies();
  }, [navigate]);

  return (
    <Card>
      <MDBox alignItems="center" pt={3} pl={3}>
        <MDTypography variant="h6">Reporte por aseguradoras</MDTypography>
      </MDBox>
      <MDBox mt={2} px={50}>
        {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : ""}
      </MDBox>

      <MDBox>
        <DataTable
          table={{
            columns: reportCompanies.columns,
            rows: reportCompanies.rows,
          }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          canSearch={false}
          canFilterDate={false}
          canExport={false}
        />
      </MDBox>
    </Card>
  );
}

export default ReportCompanies;
