import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import tututuLogo from "assets/images/logos/tututu_logo.png";

// Images
import bgImage from "assets/images/ciudad.jpg";

import service from "../../../services/apiMS/index";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Cover() {
  const [email, setEmail] = useState(); // valor input mail
  const [emailError, setEmailError] = useState(false);
  const [enterEmail, setEnterEmail] = useState(true);
  const [messageEmail, setMessageEmail] = useState();
  const [code, setCode] = useState(); // valor input code
  const [codeError, setCodeError] = useState(false);
  const [messageCode, setMessageCode] = useState();
  const [newPassword, setNewPassword] = useState(); // valor input newPass
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState(); // valor input newPassConf
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState(false);
  const [messagePassword, setMessagePassword] = useState();
  const [messageConfirm, setMessageConfirm] = useState();
  const [enterNewPassword, setEnterNewPassword] = useState(false);
  const [enterCode, setEnterCode] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    if (event.target.value) {
      setEmailError(false);
    }
    setEmail(event.target.value);
  };
  const handleCodeChange = (event) => {
    if (event.target.value) {
      setCodeError(false);
    }
    setCode(event.target.value);
  };

  const handlePasswordChange = (event) => {
    if (event.target.value) {
      setNewPasswordError(false);
    }
    setNewPassword(event.target.value);
  };

  const handlePasswordConfirmChange = (event) => {
    if (event.target.value) {
      setNewPasswordConfirmError(false);
    }
    setNewPasswordConfirm(event.target.value);
  };

  const handleClickSendMail = async () => {
    if (!email) {
      setEmailError(true);
      setMessageEmail("El email es obligatorio");
      return;
    }

    setDataProcessing(true);

    const passwordRecoveryResponse = await service.sendMailRecovery(email);

    if (passwordRecoveryResponse.ok) {
      setEnterCode(true);
      setEnterEmail(false);
    } else {
      const emailResponseData = await passwordRecoveryResponse.json();
      setEmailError(true);
      setMessageEmail(emailResponseData.message);
    }
    setDataProcessing(false);
  };

  const handleClickSendCode = async () => {
    if (!code) {
      setCodeError(true);
      setMessageCode("El código es obligatorio");
      return;
    }

    setDataProcessing(true);

    const codeResponse = await service.sendCode(code);

    if (codeResponse.ok) {
      setEnterNewPassword(true);
      setEnterCode(false);
    } else {
      const codeResponseData = await codeResponse.json();
      setCodeError(true);
      setMessageCode(codeResponseData.message);
    }
    setDataProcessing(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/authentication/sign-in");
  };

  const handleClickSendNewPassword = async () => {
    if (!newPassword) {
      setNewPasswordError(true);
      setMessagePassword("La contraseña es obligatoria");
      return;
    }
    if (!newPasswordConfirm) {
      setNewPasswordConfirmError(true);
      setMessageConfirm("La confirmación de contraseña es obligatoria");
      return;
    }

    setDataProcessing(true);
    const newPasswordResponse = await service.sendNewPassword(
      code,
      newPassword,
      newPasswordConfirm
    );

    setDataProcessing(false);

    if (newPasswordResponse.ok) {
      handleClickOpen();
    } else {
      const confirmPasswordResponseData = await newPasswordResponse.json();
      setNewPasswordError(true);
      setMessagePassword(confirmPasswordResponseData.message);
    }
  };

  const handleKeypressMail = (e) => {
    if (e.charCode === 13 && dataProcessing === false) {
      e.preventDefault();
      handleClickSendMail();
    }
  };

  const handleKeypressCode = (e) => {
    if (e.charCode === 13 && dataProcessing === false) {
      e.preventDefault();
      handleClickSendCode();
    }
  };

  const handleKeypressPassword = (e) => {
    if (e.charCode === 13 && dataProcessing === false) {
      e.preventDefault();
      handleClickSendNewPassword();
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Recuperar contraseña
          </MDTypography>
          {enterEmail && (
            <MDTypography display="block" variant="button" color="white" my={1}>
              Ingresá tu email para recuperar tu contraseña
            </MDTypography>
          )}
          {enterCode && (
            <MDTypography display="block" variant="button" color="white" my={1}>
              Ingresá el código que te enviamos a tu dirección de email
            </MDTypography>
          )}
          {enterNewPassword && (
            <MDTypography display="block" variant="button" color="white" my={1}>
              Ingresá tu nueva contraseña
            </MDTypography>
          )}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            {emailError && (
              <MDBox mb={2}>
                <Alert severity="error">{messageEmail}</Alert>
              </MDBox>
            )}
            {enterEmail && (
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  required
                  onChange={handleEmailChange}
                  autoFocus
                  onKeyPress={handleKeypressMail}
                />
              </MDBox>
            )}
            {codeError && (
              <MDBox mb={2}>
                <Alert severity="error">{messageCode}</Alert>
              </MDBox>
            )}
            {enterCode && (
              <MDBox mb={2}>
                <MDInput
                  type="code"
                  label="Código"
                  fullWidth
                  value={code}
                  required
                  onChange={handleCodeChange}
                  autoFocus
                  onKeyPress={handleKeypressCode}
                />
              </MDBox>
            )}
            {enterNewPassword && (
              <>
                {newPasswordError && (
                  <MDBox mb={2}>
                    <Alert severity="error">{messagePassword}</Alert>
                  </MDBox>
                )}
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Nueva contraseña"
                    fullWidth
                    value={newPassword}
                    required
                    onChange={handlePasswordChange}
                    autoFocus
                  />
                </MDBox>
                {newPasswordConfirmError && (
                  <MDBox mb={2}>
                    <Alert severity="error">{messageConfirm}</Alert>
                  </MDBox>
                )}
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Confirmar contraseña"
                    fullWidth
                    value={newPasswordConfirm}
                    required
                    onChange={handlePasswordConfirmChange}
                    onKeyPress={handleKeypressPassword}
                  />
                </MDBox>
              </>
            )}

            <MDBox mt={4} mb={1}>
              {enterEmail && (
                <MDButton
                  variant="gradient"
                  color="success"
                  fullWidth
                  borderRadius="lg"
                  coloredShadow="dark"
                  onClick={handleClickSendMail}
                  sx={{ minWidth: "8rem" }}
                >
                  {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : "enviar"}
                </MDButton>
              )}
              {enterCode && (
                <MDButton
                  variant="gradient"
                  color="success"
                  fullWidth
                  borderRadius="lg"
                  coloredShadow="dark"
                  onClick={handleClickSendCode}
                  sx={{ minWidth: "8rem" }}
                >
                  {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : "enviar"}
                </MDButton>
              )}

              {enterNewPassword && (
                <MDButton
                  variant="gradient"
                  color="success"
                  fullWidth
                  borderRadius="lg"
                  coloredShadow="dark"
                  onClick={handleClickSendNewPassword}
                  sx={{ minWidth: "8rem" }}
                >
                  {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : "enviar"}
                </MDButton>
              )}
            </MDBox>

            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <Typography variant="subtitle2" gutterBottom>
                  Contraseña actualizada
                </Typography>
              </DialogTitle>
              <DialogContent dividers>
                <Typography variant="body2" gutterBottom>
                  Su contraseña ha sido actualizada con éxito. Ingrese sus datos nuevamente.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button size="small" color="success" autoFocus onClick={handleClose}>
                  Continuar
                </Button>
              </DialogActions>
            </BootstrapDialog>

            <MDBox mt={3} textAlign="center">
              <MDBox component="img" src={tututuLogo} alt="20%" width="30%" />
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
