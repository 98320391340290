import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// @mui material components

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import moment from "moment";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Billing page components
import CardVehicle from "layouts/user/components/CardVehicle";
import CardInsurance from "layouts/user/components/CardInsurance";

// Overview page components
import Header from "layouts/user/components/Header";

// MS
import service from "../../services/apiMS/index";

function Overview() {
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [userInfoInsurance, setInfoInsurance] = useState([]);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [stateInfo, setStateInfo] = useState([]);
  const [userDelete, setUserDelete] = useState("");
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [gender, setGender] = useState([]);
  const [userGender, setUserGender] = useState({});
  const [taxCondition, setTaxCondition] = useState([]);
  const [userTaxCondition, setUserTaxCondition] = useState({});
  const { userId } = useParams();

  const navigate = useNavigate();

  const confirmationDeleteText = confirmationDelete
    ? "El nro. de documento debe coincidir con el del usuario a eliminar"
    : "";

  const handleTabValue = (tabValue) => {
    setCurrentTab(tabValue);
  };
  useEffect(() => {}, [currentTab]);

  useEffect(() => {
    const getUser = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setDataProcessing(true);

      const userResponse = await service.getUser({ userData, userId });

      if (!userResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (userResponse.ok) {
        const userInfoData = await userResponse.json();
        setUserInfo(userInfoData);
        setUserName(`${userInfoData.first_name} ${userInfoData.last_name}`);
        setUserImage(`${userInfoData.profile_picture}`);
      }
      setDataProcessing(false);
    };

    getUser();
  }, [navigate, userId]);

  useEffect(() => {
    const getCities = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const statesResponse = await service.getCities({ userData, stateId: userInfo.state_id });

      if (!statesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (statesResponse.ok) {
        const statesInfoData = await statesResponse.json();
        setStateInfo(statesInfoData);
      }
    };
    if (userInfo?.state_id) {
      getCities();
    }
  }, [navigate, userInfo?.state_id]);

  useEffect(() => {
    const getGenders = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const genderResponse = await service.getGenders({ userData });

      if (!genderResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (genderResponse.ok) {
        const genderInfoData = await genderResponse.json();
        setGender(genderInfoData);
      }
      setDataProcessing(false);
    };

    getGenders();
  }, [navigate]);

  useEffect(() => {
    const getTaxCondition = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const taxResponse = await service.getTaxCondition({ userData });

      if (!taxResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (taxResponse.ok) {
        const taxInfoData = await taxResponse.json();
        setTaxCondition(taxInfoData);
      }
      setDataProcessing(false);
    };

    getTaxCondition();
  }, [navigate]);

  const genderAsJSON = JSON.stringify(gender);

  useEffect(() => {
    const genderValue = JSON.parse(genderAsJSON);
    const foundGender = genderValue.find((sex) => sex.id === userInfo.sex_id);
    setUserGender(foundGender);
  }, [genderAsJSON, userInfo.sex_id]);

  const taxConditionAsJSON = JSON.stringify(taxCondition);

  useEffect(() => {
    const taxConditionValue = JSON.parse(taxConditionAsJSON);
    const foundTaxCondition = taxConditionValue.find((tax) => tax.id === userInfo.tax_condition_id);
    setUserTaxCondition(foundTaxCondition);
  }, [taxConditionAsJSON, userInfo.tax_condition_id]);

  useEffect(() => {
    const getInsurance = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setDataProcessing(true);

      const insuranceResponse = await service.getUserInsurance({ userData, userId });

      if (!insuranceResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (insuranceResponse.ok) {
        const infoInsurance = await insuranceResponse.json();
        const sortedInsuranceInfo = infoInsurance?.data
          ?.toSorted((a, b) => {
            if (a.updated_at < b.updated_at) {
              return -1;
            }
            if (a.updated_at > b.updated_at) {
              return 1;
            }
            return 0;
          })
          .reverse();
        setInfoInsurance(sortedInsuranceInfo);
      }
      setDataProcessing(false);
    };

    getInsurance();
  }, [navigate, userId]);

  const handleClickOpen = () => {
    setOpen(true);
    setConfirmationDelete(false);
  };

  const handleClose = () => {
    setOpen(false);
    setUserDelete("");
  };

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    navigate(`/users`);
  };

  const handleChange = (event) => {
    setUserDelete(event.target.value);
    setConfirmationDelete(false);
  };

  const handleDeleteUser = async () => {
    if (userInfo.document_number !== userDelete) {
      setConfirmationDelete(true);
      return;
    }
    const userDataString = localStorage.getItem("userData");

    if (!userDataString) {
      navigate("/authentication/sign-in");
      return;
    }

    const userData = JSON.parse(userDataString);
    setDataProcessing(true);

    const deleteUserResponse = await service.deleteUser({ userData, userId });

    if (deleteUserResponse.ok) {
      handleClose();
      handleConfirmOpen();
    }

    setDataProcessing(false);
    setConfirmationDelete(false);
  };

  const hasUserInfo =
    userInfo.first_name && userInfo.last_name && userInfo.document_number && userInfo.email;
  const hasUserCarInfo = userInfo?.vehicles?.length > 0;
  const hasUserInsuranceInfo = userInfoInsurance?.length > 0;

  const vehicles = userInfo.vehicles?.length > 0 ? [...userInfo.vehicles] : [];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header onSubmit={handleTabValue} userName={userName} userImage={userImage}>
        <MDBox mt={5} mb={3}>
          <div align="center">
            {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : ""}
          </div>
          <MDBox display="flex" p={3}>
            <Grid container spacing={2}>
              {hasUserInfo && currentTab === 0 && (
                <Grid item xs={12} md={12} xl={12}>
                  <ProfileInfoCard
                    title="Información Personal"
                    info={{
                      nombre: `${userInfo.first_name}`,
                      apellido: `${userInfo.last_name}`,
                      tipo_documento: `${userInfo.document_type ?? "Sin datos"}`,
                      documento: `${userInfo.document_number}`,
                      fecha_nacimiento: `${
                        userInfo.dob ? moment(userInfo.dob).format("DD/MM/YYYY") : "Sin datos"
                      }`,
                      género: `${userGender?.value ?? "Sin datos"}`,
                      condición_IVA: `${userTaxCondition?.value ?? "Sin datos"}`,
                      email: `${userInfo.email ?? "Sin datos"}`,
                      dirección: `${userInfo.address ?? "Sin datos"}`,
                      teléfono: `${userInfo.phone ?? "Sin datos"}`,
                      provincia: `${stateInfo.name ?? "Sin datos"}`,
                      ciudad:
                        stateInfo?.cities?.find((city) => city.id === userInfo.city_id)?.name ??
                        "Sin datos",
                      creación: `${moment(userInfo.created_at).format("DD/MM/YYYY")}`,
                      actualización: `${moment(userInfo.updated_at).format("DD/MM/YYYY")}`,
                    }}
                    action={{ route: `/formuser/${userInfo.id}`, tooltip: "Editar Usuario" }}
                    shadow={false}
                  />
                </Grid>
              )}
              {hasUserInsuranceInfo &&
                currentTab === 1 &&
                userInfoInsurance.map((infoInsurance) => (
                  <Grid key={infoInsurance.id} item xs={12} md={12} lg={12}>
                    <CardInsurance
                      insuranceId={`${infoInsurance.id}`}
                      companyName={`${infoInsurance.insurance_company.name}`}
                      policy={`${infoInsurance.name}`}
                      status={`${infoInsurance.status}`}
                      update={`${infoInsurance.updated_at}`}
                      vehicleId={`${infoInsurance.vehicle_id}`}
                      vehicleBrand={`${infoInsurance.vehicle?.brand ?? ""}`}
                      vehicleModel={`${infoInsurance.vehicle?.model ?? ""}`}
                      vehicleEngine={`${infoInsurance.vehicle?.engine_number ?? ""}`}
                    />
                  </Grid>
                ))}
              {!hasUserInsuranceInfo && currentTab === 1 && !dataProcessing && (
                <Grid item xs={12} md={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="medium">
                    <MDBox display="flex" justifyContent="center">
                      Sin datos
                    </MDBox>
                  </MDTypography>
                </Grid>
              )}

              {hasUserCarInfo &&
                currentTab === 2 &&
                vehicles.map((vehicle) => (
                  <Grid key={vehicle.id} item xs={12} md={12} lg={12}>
                    <CardVehicle
                      vehicleId={`${vehicle.id}`}
                      brand={`${vehicle.brand}`}
                      model={`${vehicle.model}`}
                      licence={`${vehicle.licence_plate}`}
                      year={`${vehicle.year}`}
                      category={`${vehicle.category}`}
                      use={`${vehicle.use_type}`}
                      created={`${vehicle.created_at}`}
                    />
                  </Grid>
                ))}
              {!hasUserCarInfo && currentTab === 2 && !dataProcessing && (
                <Grid item xs={12} md={12} lg={12}>
                  <MDTypography variant="h6" fontWeight="medium">
                    <MDBox display="flex" justifyContent="center">
                      Sin datos
                    </MDBox>
                  </MDTypography>
                </Grid>
              )}
            </Grid>
          </MDBox>
          <MDBox
            mt={1}
            mb={1}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            ml={2}
            mr={1}
          >
            <MDButton type="submit" variant="gradient" color="error" onClick={handleClickOpen}>
              <Icon>delete</Icon>&nbsp; eliminar
            </MDButton>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>
                {" "}
                <MDBox align="center">
                  <Icon color="warning" fontSize="large" align="center">
                    warning
                  </Icon>{" "}
                </MDBox>
                <MDBox align="center">¿Está seguro de eliminar el usuario?</MDBox>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Para confirmar ingrese el número de documento del usuario.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="document_number"
                  value={userDelete}
                  label="Nro. de Documento"
                  type="document_number"
                  fullWidth
                  variant="standard"
                  onChange={handleChange}
                  error={confirmationDelete}
                  helperText={confirmationDeleteText}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleDeleteUser}>Eliminar</Button>
              </DialogActions>
            </Dialog>
            <Dialog open={confirmOpen} onClose={handleClose}>
              <DialogTitle display="flex" justifyContent="center" mar>
                <MDBox>
                  <CheckCircleIcon color="success" fontSize="large" margin-left="0.5rem" />
                  Usuario eliminado.
                </MDBox>
              </DialogTitle>
              <DialogActions display="flex" justifyContent="center">
                <Button onClick={handleCloseConfirm}>Aceptar</Button>
              </DialogActions>
            </Dialog>
            <MDButton
              type="submit"
              variant="gradient"
              color="dark"
              onClick={() => navigate(`/users`)}
            >
              <Icon>undo</Icon>&nbsp; volver
            </MDButton>
          </MDBox>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
