import MDTypography from "components/MDTypography";

// @mui material components
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Link from "@mui/material/Link";

import moment from "moment";

// eslint-disable-next-line no-unused-vars
export default function data(vehicleData) {
  return {
    columns: [
      { Header: "id", accessor: "id", align: "center" },
      { Header: "contrato", accessor: "contract", align: "center" },
      { Header: "negocio", accessor: "liendholder_key", align: "center" },
      { Header: "usuario", accessor: "user", align: "center" },
      { Header: "dni", accessor: "dni", align: "center" },
      { Header: "creación", accessor: "created_at", align: "center" },
      { Header: "marca", accessor: "brand", align: "left" },
      { Header: "modelo", accessor: "model", align: "center" },
      { Header: "año", accessor: "year", align: "center" },
      { Header: "patente", accessor: "licence_plate", align: "center" },
      { Header: "acreedor", accessor: "lienholder", align: "center" },
      { Header: "aseguradora", accessor: "insurance_company", align: "center" },
      { Header: "categoría", accessor: "category", align: "center" },
      { Header: "chasis", accessor: "chassis_number", align: "center" },
      { Header: "uso", accessor: "use_type", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    // eslint-disable-next-line no-shadow, no-unused-vars
    rows: vehicleData.map((data) => ({
      id: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.id}
        </MDTypography>
      ),
      contract: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.contract_id ?? "Sin datos"}
        </MDTypography>
      ),
      liendholder_key: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.lienholder.key ?? "Sin datos"}
        </MDTypography>
      ),
      user: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.user?.first_name} {data.user?.last_name ?? "Sin datos"}
        </MDTypography>
      ),
      dni: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.user?.document_number ?? "Sin datos"}
        </MDTypography>
      ),
      created_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.created_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      brand: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.brand}
        </MDTypography>
      ),
      model: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.model}
        </MDTypography>
      ),
      year: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.year}
        </MDTypography>
      ),
      licence_plate: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.licence_plate}
        </MDTypography>
      ),
      lienholder: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.lienholder.name}
        </MDTypography>
      ),
      insurance_company: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.insurance_company?.name ?? ""}
        </MDTypography>
      ),
      category: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.category}
        </MDTypography>
      ),
      chassis_number: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.chassis_number}
        </MDTypography>
      ),
      use_type: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.use_type}
        </MDTypography>
      ),
      action: (
        <Link href={`vehicle/${data.id}`}>
          <ChevronRightIcon color="action" fontSize="medium" />
        </Link>
      ),
    })),
  };
}
