import MDTypography from "components/MDTypography";

import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import Icon from "@mui/material/Icon";

import Link from "@mui/material/Link";

import moment from "moment";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 6px;
      margin: 0;
      min-width: 100px;
      border-radius: 12px;
      overflow: auto;
      outline: 0px;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      z-index: 1;
      `
);

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => `
      list-style: none;
      padding: 5px;
      border-radius: 5px;
      cursor: default;
      user-select: none;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${menuItemClasses.focusVisible} {
        outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${menuItemClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${menuItemClasses.disabled}) {
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

// eslint-disable-next-line no-unused-vars
export default function data(tipsData, deleteTipHandler) {
  return {
    columns: [
      { Header: "id", accessor: "id", align: "left" },
      { Header: "título", accessor: "title", align: "left" },
      { Header: "categoría", accessor: "category", align: "left" },
      { Header: "creación", accessor: "created_at", align: "center" },
      { Header: "actualización", accessor: "updated_at", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    // eslint-disable-next-line no-shadow, no-unused-vars
    rows: tipsData.map((data) => ({
      id: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.id}
        </MDTypography>
      ),
      title: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.title}
        </MDTypography>
      ),
      category: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.category.title}
        </MDTypography>
      ),
      created_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.created_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      updated_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.updated_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      action: (
        <Dropdown>
          <MenuButton slots={{ root: "div" }}>
            <Icon>more_vert</Icon>
          </MenuButton>
          <Menu slots={{ listbox: StyledListbox }}>
            <Link href={`formtip/${data.id}`}>
              <StyledMenuItem>Editar</StyledMenuItem>
            </Link>
            <StyledMenuItem
              onClick={() => {
                deleteTipHandler(data.id);
              }}
            >
              Eliminar
            </StyledMenuItem>
          </Menu>
        </Dropdown>
      ),
    })),
  };
}
