// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function VehicleInfoCard({ title, vehicleId, info, action, shadow }) {
  const labels = [];
  const values = [];

  const navigate = useNavigate();

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[a-z\s]+/)) {
      const lowercaseLetter = Array.from(el).find((i) => i.match(/[a-z]+/));
      const newElement = el.replace(lowercaseLetter, ` ${lowercaseLetter.toUpperCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={3}>
      <Grid item display="flex" flexDirection="row">
        <TextField label={label} value={values[key]} variant="standard" fullWidth />
      </Grid>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography component={Link} to={action.route} variant="body2" color="secondary">
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox p={2} mt={1}>
        <Grid container display="flex" flexDirection="row">
          {renderItems}
        </Grid>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" px={2}>
        <MDBox>
          <Link to={`/insurancesVehicles/${vehicleId}`}>
            <MDButton variant="outlined" color="secondary" size="small">
              Listado de pólizas
            </MDButton>
          </Link>
        </MDBox>
        <MDBox mt={1} mb={2} display="flex" flexDirection="row-reverse">
          <MDButton
            type="submit"
            variant="gradient"
            color="dark"
            bgColor="dark"
            borderRadius="lg"
            coloredShadow="dark"
            onClick={() => navigate(-1)}
          >
            <Icon>undo</Icon>&nbsp; volver
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
VehicleInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
VehicleInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  vehicleId: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default VehicleInfoCard;
