import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";

import moment from "moment";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function Vehicle({ vehicleId, brand, model, licence, year, category, use, created, noGutter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="li"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox display="flex" flexDirection={{ xs: "row", sm: "row" }} mb={2}>
          <MDTypography variant="caption" color="text">
            Marca:
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="uppercase">
              {brand}
            </MDTypography>
          </MDTypography>
          <MDTypography ml={4} variant="caption" color="text">
            Modelo:
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="uppercase">
              {model}
            </MDTypography>
          </MDTypography>
          <MDTypography ml={4} variant="caption" color="text">
            Patente:
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="uppercase">
              {licence}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Año:
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="uppercase">
              {year}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Categoría:
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="uppercase">
              {category}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Uso:
            <MDTypography ml={1} variant="caption" fontWeight="medium" textTransform="uppercase">
              {use}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Fecha de creación:
            <MDTypography ml={1} variant="caption" fontWeight="medium">
              {moment(created).format("DD/MM/YYYY-HH:MM")}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox pt={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={2}>
              <Link to={`/vehicle/${vehicleId}`}>
                <MDButton variant="outlined" color="secondary" size="small">
                  Ver Detalle
                </MDButton>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Link to={`/insurancesVehicles/${vehicleId}`}>
                <MDButton variant="outlined" color="secondary" size="small">
                  Listado de pólizas
                </MDButton>
              </Link>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
Vehicle.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Vehicle.propTypes = {
  vehicleId: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  licence: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  use: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Vehicle;
