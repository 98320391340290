import { useCallback, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import Projects from "layouts/dashboard/components/Projects";

import { useNavigate } from "react-router-dom";

// MS
import service from "../../services/apiMS/index";

const USER_PERIOD_MONTHLY = "monthly";
const VEHICLE_PERIOD_MONTHLY = "monthly";
const INSURANCE_PERIOD_MONTHY = "monthly";

function Dashboard() {
  const [reportUsers, setReportUsers] = useState(0);
  const [reportVehicles, setReportVehicles] = useState(0);
  const [reportInsurances, setReportInsurances] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [userDataProcessing, setUserDataProcessing] = useState(false);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [vehiclesDataProcessing, setVehiclesDataProcessing] = useState(false);
  const [totalInsurances, setTotalInsurances] = useState(0);
  const [insurancesDataProcessing, setInsurancesDataProcessing] = useState(false);
  const [periodUsers, setPeriodUsers] = useState(USER_PERIOD_MONTHLY);
  const [periodVehicles, setPeriodVehicles] = useState(VEHICLE_PERIOD_MONTHLY);
  const [periodInsurances, setPeriodInsurances] = useState(INSURANCE_PERIOD_MONTHY);
  const [reportUsersProcessing, setReportUsersProccessing] = useState(false);
  const [reportVehiclesProcessing, setReportVehiclesProccessing] = useState(false);
  const [reportInsurancesProcessing, setReportInsurancesProccessing] = useState(false);
  const [reportVehiclesLabel, setReportVehiclesLabel] = useState();
  const [reportVehiclesDataSet, setReportVehiclesDataSet] = useState();
  const [reportUsersLabel, setReportUsersLabel] = useState();
  const [reportUsersDataSet, setReportUsersDataSet] = useState();
  const [reportInsurancesLabel, setReportInsurancesLabel] = useState();
  const [reportInsurancesDataSet, setReportInsurancesDataSet] = useState();

  const navigate = useNavigate();
  const pageIndex = 0;

  useEffect(() => {
    const getReportUsers = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setReportUsersProccessing(true);
      const reportUsersResponse = await service.getReportUsers({ userData, periodUsers });

      if (!reportUsersResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (reportUsersResponse.ok) {
        const usersReportData = await reportUsersResponse.json();
        setReportUsers(usersReportData);
      }
      setReportUsersProccessing(false);
    };
    getReportUsers();
  }, [navigate, periodUsers]);

  useEffect(() => {
    const getReportVehicles = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setReportVehiclesProccessing(true);
      const reportVehiclesResponse = await service.getReportVehicles({ userData, periodVehicles });

      if (!reportVehiclesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (reportVehiclesResponse.ok) {
        const vehiclesReportData = await reportVehiclesResponse.json();
        setReportVehicles(vehiclesReportData);
      }
      setReportVehiclesProccessing(false);
    };
    getReportVehicles();
  }, [navigate, periodVehicles]);

  useEffect(() => {
    const getReportInsurances = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setReportInsurancesProccessing(true);
      const reportInsurancesResponse = await service.getReportInsurances({
        userData,
        periodInsurances,
      });

      if (!reportInsurancesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (reportInsurancesResponse.ok) {
        const insurancesReportData = await reportInsurancesResponse.json();

        setReportInsurances(insurancesReportData);
      }
      setReportInsurancesProccessing(false);
    };
    getReportInsurances();
  }, [navigate, periodInsurances]);

  useEffect(() => {
    const reportVehiclesData = Object.values(reportVehicles);
    let newReportVehiclesLabel;
    if (periodVehicles === VEHICLE_PERIOD_MONTHLY) {
      newReportVehiclesLabel = reportVehiclesData.map((item) => {
        const [year, month] = item.month?.split("-") ?? ["", ""];
        return `${month}-${year}`;
      });
    } else {
      newReportVehiclesLabel = reportVehiclesData.map((item) => {
        const [year, month, day] = item.date_from?.split("-") ?? ["", "", ""];
        return `${day}-${month}-${year}`;
      });
    }
    setReportVehiclesLabel(newReportVehiclesLabel);

    const newReportVehiclesDataSet = reportVehiclesData.map((item) => item.total);
    setReportVehiclesDataSet(newReportVehiclesDataSet);
  }, [periodVehicles, reportVehicles]);

  useEffect(() => {
    const reportUsersData = Object.values(reportUsers);
    let newReportUsersLabel;
    if (periodUsers === USER_PERIOD_MONTHLY) {
      newReportUsersLabel = reportUsersData.map((item) => {
        const [year, month] = item.month?.split("-") ?? ["", ""];
        return `${month}-${year}`;
      });
    } else {
      newReportUsersLabel = reportUsersData.map((item) => {
        const [year, month, day] = item.date_from?.split("-") ?? ["", "", ""];
        return `${day}-${month}-${year}`;
      });
    }
    setReportUsersLabel(newReportUsersLabel);
    const newReportUsersDataSet = reportUsersData.map((item) => item.total);
    setReportUsersDataSet(newReportUsersDataSet);
  }, [periodUsers, reportUsers]);

  useEffect(() => {
    const reportInsurancesData = Object.values(reportInsurances);
    let newReportInsurancesLabel;
    if (periodInsurances === INSURANCE_PERIOD_MONTHY) {
      newReportInsurancesLabel = reportInsurancesData.map((item) => {
        const [year, month] = item.month?.split("-") ?? ["", ""];
        return `${month}-${year}`;
      });
    } else {
      newReportInsurancesLabel = reportInsurancesData.map((item) => {
        const [year, month, day] = item.date_from?.split("-") ?? ["", "", ""];
        return `${day}-${month}-${year}`;
      });
    }
    setReportInsurancesLabel(newReportInsurancesLabel);
    const newReportInsurancesDataSet = reportInsurancesData.map((item) => item.total);
    setReportInsurancesDataSet(newReportInsurancesDataSet);
  }, [periodInsurances, reportInsurances]);

  useEffect(() => {
    const getUsers = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setUserDataProcessing(true);
      const usersResponse = await service.getUsers({ userData, pageIndex });

      if (!usersResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (usersResponse.ok) {
        const usersData = await usersResponse.json();
        setTotalUser(usersData.total);
      }
      setUserDataProcessing(false);
    };
    getUsers();
  }, [navigate]);

  useEffect(() => {
    const getVehicles = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setVehiclesDataProcessing(true);
      const vehiclesResponse = await service.getVehicles({ userData, pageIndex });

      if (!vehiclesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (vehiclesResponse.ok) {
        const vehiclesData = await vehiclesResponse.json();
        setTotalVehicles(vehiclesData.total);
      }
      setVehiclesDataProcessing(false);
    };

    getVehicles();
  }, [navigate]);

  useEffect(() => {
    const getInsurances = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }
      const userData = JSON.parse(userDataString);

      setInsurancesDataProcessing(true);
      const insurancesResponse = await service.getInsurances({ userData, pageIndex });

      if (!insurancesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (insurancesResponse.ok) {
        const insurancesData = await insurancesResponse.json();
        setTotalInsurances(insurancesData.total);
      }
      setInsurancesDataProcessing(false);
    };

    getInsurances();
  }, [navigate]);

  const handleChangePeriodUsers = useCallback((event) => {
    setPeriodUsers(event.target.value);
  }, []);

  const handleChangePeriodVehicles = useCallback((event) => {
    setPeriodVehicles(event.target.value);
  }, []);

  const handleChangePeriodInsurances = useCallback((event) => {
    setPeriodInsurances(event.target.value);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="person_add"
                title="Usuarios"
                count={
                  userDataProcessing ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : (
                    totalUser
                  )
                }
                percentage={{
                  color: "success",
                  label: "Total de usuarios",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="directions_car"
                title="Vehículos"
                count={
                  vehiclesDataProcessing ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : (
                    totalVehicles
                  )
                }
                percentage={{
                  color: "success",
                  label: "Total de vehículos",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="content_copy"
                title="Pólizas"
                count={
                  insurancesDataProcessing ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : (
                    totalInsurances
                  )
                }
                percentage={{
                  color: "success",
                  label: "Total de pólizas",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="total usuarios"
                  progress={reportUsersProcessing}
                  description={
                    <FormGroup>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={periodUsers}
                        onChange={handleChangePeriodUsers}
                      >
                        <FormControlLabel value="monthly" control={<Radio />} label="Mensual" />
                        <FormControlLabel value="weekly" control={<Radio />} label="Semanal" />
                      </RadioGroup>
                    </FormGroup>
                  }
                  chart={{
                    labels: reportUsersLabel,
                    datasets: {
                      label: "Usuarios",
                      data: reportUsersDataSet,
                    },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="total vehiculos"
                  progress={reportVehiclesProcessing}
                  description={
                    <FormGroup>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={periodVehicles}
                        onChange={handleChangePeriodVehicles}
                      >
                        <FormControlLabel value="monthly" control={<Radio />} label="Mensual" />
                        <FormControlLabel value="weekly" control={<Radio />} label="Semanal" />
                      </RadioGroup>
                    </FormGroup>
                  }
                  chart={{
                    labels: reportVehiclesLabel,
                    datasets: {
                      label: "Vehículos",
                      data: reportVehiclesDataSet,
                    },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="primary"
                  title="total pólizas"
                  progress={reportInsurancesProcessing}
                  description={
                    <FormGroup>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={periodInsurances}
                        onChange={handleChangePeriodInsurances}
                      >
                        <FormControlLabel value="monthly" control={<Radio />} label="Mensual" />
                        <FormControlLabel value="weekly" control={<Radio />} label="Semanal" />
                      </RadioGroup>
                    </FormGroup>
                  }
                  chart={{
                    labels: reportInsurancesLabel,
                    datasets: {
                      label: "Pólizas",
                      data: reportInsurancesDataSet,
                    },
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
              <Projects />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
