import { useEffect, useCallback, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";

/* eslint-disable jsx-a11y/label-has-associated-control */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import AddPhotoIcon from "@mui/icons-material/AddAPhoto";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import imagedefault from "../../assets/images/default.png";
// MS
import service from "../../services/apiMS/index";

import validator from "./validateTip";

const initialValues = {
  id: "",
  category_id: "",
  title: "",
  description: "",
  body: "",
  image: "",
};

function FormTip() {
  const { tipId } = useParams();
  const navigate = useNavigate();
  const [saveResponse, setSaveResponse] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [sendTipImage, setSendTipImage] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [dataProcessing, setDataProcessing] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const loading = dataProcessing && categories.length === 0;

  const { handleSubmit, handleChange, values, resetForm, errors, setFieldValue } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const formValuesToSend = {
        ...formValues,
        image: sendTipImage ? formValues.image : undefined,
      };
      setSaveLoading(true);

      const saveTipResponse = await service.saveTip({ userData, formValues: formValuesToSend });

      if (saveTipResponse.ok) {
        setSaveResponse(true);
        setOpen(true);
      } else {
        setSaveResponse(false);
      }
      setSaveLoading(false);
    },
    validate: (v) => validator(v),
  });

  useEffect(() => {
    const getTip = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const tipResponse = await service.getTip({ userData, tipId });

      if (!tipResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (tipResponse.ok) {
        const tipInfoData = await tipResponse.json();
        resetForm({
          values: {
            id: tipInfoData.id,
            category_id: tipInfoData.category_id,
            title: tipInfoData.title,
            description: tipInfoData.description,
            body: tipInfoData.body,
            image: tipInfoData.image,
          },
        });
        setSelectedImage(tipInfoData.image);
      }
    };

    getTip();
  }, [navigate, resetForm, tipId]);

  useEffect(() => {
    const getCategories = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const categoriesResponse = await service.getCategoriesTip({ userData });

      if (!categoriesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (categoriesResponse.ok) {
        const categoriesData = await categoriesResponse.json();
        setCategories(categoriesData);
      }
      setDataProcessing(false);
    };

    getCategories();
  }, [navigate, values.state_id]);

  const categoriesAsJSON = JSON.stringify(categories);

  useEffect(() => {
    const categoriesValue = JSON.parse(categoriesAsJSON);
    const foundCategory = categoriesValue.find((category) => category.id === values.category_id);
    setSelectedCategory(foundCategory);
  }, [categoriesAsJSON, values.category_id]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFieldValue("image", file);
      setSendTipImage(true);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Editar tip
            </MDTypography>
          </MDBox>
          <form action="" className="form-container" onSubmit={handleSubmit}>
            {saveResponse === true && (
              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Tip actualizado
                </Alert>
              </Collapse>
            )}
            {saveResponse === false && (
              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="error"
                  sx={{ mb: 2 }}
                >
                  Ocurrió un error
                </Alert>
              </Collapse>
            )}
            <MDBox display="flex" mt={4} mb={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                  <Grid container spacing={3} px={3}>
                    <Grid item xs={12}>
                      <TextField
                        label="Título"
                        variant="outlined"
                        id="title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.title}
                        helperText={errors.title}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        disablePortal
                        id="category_id"
                        name="category_id"
                        value={selectedCategory}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        getOptionLabel={(option) => option.title}
                        onChange={(_, value) => {
                          setFieldValue("category_id", value?.id);
                        }}
                        options={categories?.map((category) => ({
                          title: category.title,
                          id: category.id,
                        }))}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Categoría"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                            error={errors.category_id}
                            helperText={errors.category_id}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Descripción"
                        variant="outlined"
                        id="description"
                        multiline
                        rows={4}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        error={errors.description}
                        helperText={errors.description}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Texto"
                        variant="outlined"
                        id="body"
                        multiline
                        rows={4}
                        name="body"
                        value={values.body}
                        onChange={handleChange}
                        error={errors.body}
                        helperText={errors.body}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <MDBox display="flex" px={3}>
                    <div
                      {...getRootProps()}
                      className={`dropzone ${isDragActive ? "drag-active" : ""}`}
                    >
                      <input {...getInputProps()} />

                      <Box
                        component="img"
                        sx={{
                          height: "100%",
                          width: "100%",
                          maxHeight: { xs: 300, md: 350 },
                          maxWidth: { xs: 300, md: 350 },
                          p: 1,
                          border: "1px dashed grey",
                        }}
                        src={selectedImage ?? imagedefault}
                      />

                      <IconButton>
                        <AddPhotoIcon />
                      </IconButton>
                    </div>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mb={3} mr={3} display="flex" flexDirection="row-reverse">
              <Stack direction="row" spacing={2}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  onClick={() => navigate(-1)}
                >
                  <Icon>undo</Icon>&nbsp; volver
                </MDButton>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="success"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  onSubmit={handleSubmit}
                  sx={{ minWidth: "8rem" }}
                >
                  {saveLoading ? (
                    <CircularProgress color="inherit" size="1.2rem" />
                  ) : (
                    <>
                      <Icon>save</Icon>&nbsp; guardar
                    </>
                  )}
                </MDButton>
              </Stack>
            </MDBox>
          </form>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default FormTip;
