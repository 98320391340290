// const usPhoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;
const nameRegex = /^([a-zA-Z0-9\sÁÉÍÓÚáéíóúÑñ]+)$/;
const yearRegex = /^[0-9]{4}$/;

export default (values) => {
  const errors = {};

  if (!values.brand) {
    errors.brand = "La marca es requerida";
  }

  if (values.brand && !nameRegex.test(values.brand)) {
    errors.brand = "Formato inválido";
  }

  if (!values.model) {
    errors.model = "El modelo es requerido";
  }

  if (values.model && !nameRegex.test(values.model)) {
    errors.model = "Formato inválido";
  }

  if (!values.year && values.year !== 0) {
    errors.year = "El año es requerido";
  }

  if (values.year && !yearRegex.test(values.year)) {
    errors.year = "Formato inválido";
  }

  if (!values.engine_number) {
    errors.engine_number = "La patente es requerida";
  }

  if (!values.contract_id) {
    errors.contract_id = "El número de contrato es requerido";
  }

  if (!values.state_id) {
    errors.state_id = "La provincia es requerida";
  }

  if (!values.city_id) {
    errors.city_id = "La ciudad es requerida";
  }

  return errors;
};
