export default (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = "El título es requerido";
  }

  if (!values.category_id) {
    errors.category_id = "La categoría es requerida";
  }

  if (!values.description) {
    errors.description = "La descripción es requerida";
  }

  if (!values.body) {
    errors.body = "El texto es requerido";
  }

  return errors;
};
