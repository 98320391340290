import MDTypography from "components/MDTypography";

// @mui material components
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Link from "@mui/material/Link";

import moment from "moment";

// eslint-disable-next-line no-unused-vars
export default function data(usersData) {
  return {
    columns: [
      { Header: "id", accessor: "user_id", align: "left" },
      { Header: "usuario", accessor: "username", align: "left" },
      { Header: "nombre", accessor: "first_name", align: "left" },
      { Header: "apellido", accessor: "last_name", align: "left" },
      { Header: "documento", accessor: "document_number", align: "center" },
      { Header: "email", accessor: "email", align: "center" },
      { Header: "teléfono", accessor: "phone", align: "center" },
      { Header: "creación", accessor: "created_at", align: "center" },
      { Header: "actualización", accessor: "updated_at", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    // eslint-disable-next-line no-shadow, no-unused-vars
    rows: usersData.map((data) => ({
      user_id: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.id}
        </MDTypography>
      ),
      username: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.username}
        </MDTypography>
      ),
      first_name: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.first_name}
        </MDTypography>
      ),
      last_name: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.last_name}
        </MDTypography>
      ),
      document_number: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.document_number}
        </MDTypography>
      ),
      email: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.email}
        </MDTypography>
      ),
      phone: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.phone}
        </MDTypography>
      ),
      created_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.created_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      updated_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.updated_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),

      action: (
        <Link href={`user/${data.id}`}>
          <ChevronRightIcon color="action" fontSize="medium" />
        </Link>
      ),
    })),
  };
}
