/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
// eslint-disable-next-line no-unused-vars
import buildRolesTableData from "layouts/roles/data/rolesTableData";
import { useNavigate } from "react-router-dom";

// MS
import service from "../../services/apiMS/index";

function Tables() {
  // const { columns, rows } = authorsTableData();
  // eslint-disable-next-line no-unused-vars
  const [rolesTableData, setRolesTableData] = useState({
    columns: [],
    rows: [],
  });
  const [pageCount, setPageCount] = useState(0);
  const [dataProcessing, setDataProcessing] = useState(false);

  const navigate = useNavigate();

  const fetchData = useCallback(async ({ pageIndex }) => {
    const userDataString = localStorage.getItem("userData");
    if (!userDataString) {
      navigate("/authentication/sign-in");
      return;
    }

    const userData = JSON.parse(userDataString);

    setDataProcessing(true);
    const rolesResponse = await service.getRoles({ userData, pageIndex: pageIndex + 1 });

    if (!rolesResponse.ok) {
      navigate("/authentication/sign-in");
    }

    if (rolesResponse.ok) {
      const rolesData = await rolesResponse.json();
      const newRolesTableData = buildRolesTableData(rolesData.data);
      setRolesTableData(newRolesTableData);
      setPageCount(rolesData.last_page);
    }
    setDataProcessing(false);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <MDTypography variant="h6" color="white">
                  Roles
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                {rolesTableData && (
                  <DataTable
                    canSearch={false}
                    canFilterDate={false}
                    canExport={false}
                    table={{
                      columns: rolesTableData.columns,
                      rows: rolesTableData.rows,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    fetchData={fetchData}
                    pageCount={pageCount}
                    noEndBorder
                  />
                )}
                <MDBox pb={6}>
                  <div align="center">
                    {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : ""}
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
