import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VehicleInfoCard from "examples/Cards/InfoCards/VehicleInfoCard";

// Overview page components
import Header from "layouts/vehicle/components/InfoVehicle";

// MS
import service from "../../services/apiMS/index";

function VehicleInformation() {
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [dataProcessing, setDataProcessing] = useState(false);
  const [stateInfo, setStateInfo] = useState([]);
  const { vehicleId } = useParams();
  const navigate = useNavigate();

  const usuario =
    vehicleInfo.user?.first_name && vehicleInfo.user?.last_name
      ? `${vehicleInfo.user.first_name} ${vehicleInfo.user.last_name}`
      : "Sin datos";

  useEffect(() => {
    const getVehicle = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const vehicleResponse = await service.getVehicle({ userData, vehicleId });

      if (!vehicleResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (vehicleResponse.ok) {
        const vehicleInfoData = await vehicleResponse.json();
        setVehicleInfo(vehicleInfoData);
      }
      setDataProcessing(false);
    };

    getVehicle();
  }, []);

  useEffect(() => {
    const getCities = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const statesResponse = await service.getCities({ userData, stateId: vehicleInfo.state_id });

      if (!statesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (statesResponse.ok) {
        const statesInfoData = await statesResponse.json();
        setStateInfo(statesInfoData);
      }
    };
    if (vehicleInfo?.state_id) {
      getCities();
    }
  }, [navigate, vehicleInfo?.state_id]);

  const hasVehicleInfo = vehicleInfo.id;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header>
        <MDBox mt={1}>
          {dataProcessing && (
            <MDBox mt={4} pb={6}>
              <div align="center">
                <CircularProgress color="inherit" size="1.5rem" />
              </div>
            </MDBox>
          )}
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={12} xl={12}>
              {hasVehicleInfo && (
                <VehicleInfoCard
                  title="Detalle del Vehículo"
                  vehicleId={vehicleInfo.id}
                  info={{
                    marca: `${vehicleInfo.brand}`,
                    modelo: `${vehicleInfo.model}`,
                    año: `${vehicleInfo.year}`,
                    patente: `${vehicleInfo.licence_plate}`,
                    motor: `${vehicleInfo.engine_number}`,
                    acreedor: vehicleInfo.lienholder?.name ?? "Sin datos",
                    aseguradora: vehicleInfo.insurance_company?.name ?? "Sin datos",
                    contrato: `${vehicleInfo.contract_id}`,
                    categoría: `${vehicleInfo.category}`,
                    chasis: `${vehicleInfo.chassis_number}`,
                    uso: `${vehicleInfo.use_type}`,
                    dirección: vehicleInfo.address ?? "Sin datos",
                    provincia: stateInfo.name ?? "Sin datos",
                    ciudad:
                      stateInfo?.cities?.find((city) => city.id === +vehicleInfo.city_id)?.name ??
                      "Sin datos",
                    usuario: `${usuario}`,
                  }}
                  action={{ route: `/formvehicle/${vehicleInfo.id}`, tooltip: "Editar Vehículo" }}
                  shadow={false}
                />
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default VehicleInformation;
