/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
// import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

// Material Dashboard 2 React components
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function DataTable({
  entriesPerPage,
  canSearch,
  canFilterDate,
  canExport,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  fetchData,
  fetchDataExport,
  pageCount: controlledPageCount,
  children,
  handleClickRow,
}) {
  const { light } = colors;
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [open, setOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [dataExport, setDataExport] = useState();
  const [errorExport, setErrorExport] = useState(false);

  // Search input state handle
  const onSearchChange = (value) => {
    gotoPage(0);
    setSearch(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchDataWithParam = useAsyncDebounce(() => {
    const dateFromFormat = dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null;
    const dateToFormat = dateTo ? dayjs(dateTo).format("YYYY-MM-DD") : null;
    fetchData({ pageIndex, search, dateFrom: dateFromFormat, dateTo: dateToFormat, sortBy });
  }, 1000);

  useEffect(() => {
    if (typeof pageIndex !== "undefined") {
      fetchDataWithParam();
    }
  }, [fetchDataWithParam, pageIndex, search, dateFrom, dateTo, sortBy]);

  const handleExport = async () => {
    setExportLoading(true);
    setErrorExport(false);
    const dateFromFormat = dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null;
    const dateToFormat = dateTo ? dayjs(dateTo).format("YYYY-MM-DD") : null;
    const exportData = await fetchDataExport({
      pageIndex,
      search,
      dateFrom: dateFromFormat,
      dateTo: dateToFormat,
      sortBy,
    });

    if (exportData) {
      setDataExport(exportData);
      setOpen(true);
    } else {
      setErrorExport(true);
    }
    setExportLoading(false);
  };

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const showOptions = errorExport || canSearch || canFilterDate || children || canExport;

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {errorExport ? <Alert severity="error">Ocurrió un error al exportar</Alert> : ""}
      {showOptions && (
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" p={3}>
          {canSearch && (
            <MDBox width="12rem" ml="15">
              <MDInput
                placeholder="Buscar..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
          {canFilterDate && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]} sx={{ overflow: "hidden" }}>
                <DatePicker
                  label="Fecha desde"
                  value={dateFrom}
                  format="DD/MM/YYYY"
                  onChange={(newValue) => setDateFrom(newValue)}
                  slotProps={{ textField: { size: "small" } }}
                />
                <DatePicker
                  label="Fecha hasta"
                  value={dateTo}
                  format="DD/MM/YYYY"
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(newValue) => setDateTo(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          )}

          {children}
          {canExport && (
            <>
              <MDBox display="flex" flexDirection="row-reverse">
                <MDButton
                  color="success"
                  size="small"
                  variant="contained"
                  endIcon={<SystemUpdateAltIcon />}
                  onClick={handleExport}
                  sx={{ minWidth: "8rem" }}
                >
                  {exportLoading ? <CircularProgress color="inherit" size="1.5rem" /> : "exportar"}
                </MDButton>
              </MDBox>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Exportación exitosa</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Para descargar su exportación siga el siguiente link:
                    <Link href={dataExport}>CLICK AQUÍ</Link>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </MDBox>
      )}

      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                sx={{ "&:hover": { backgroundColor: light.main, cursor: "pointer" } }}
                onClick={() => {
                  handleClickRow(row);
                }}
              >
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entries}
              onChange={(event, newValue) => {
                setEntriesPerPage(parseInt(newValue, 10));
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;entries per page
            </MDTypography>
          </MDBox>
        )}

        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "success"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 15, entries: [5, 10, 15, 20, 25] },
  canSearch: true,
  canFilterDate: true,
  canExport: true,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "success" },
  isSorted: true,
  noEndBorder: false,
  fetchData: () => {},
  fetchDataExport: () => {},
  dataExport: "",
  pageCount: 0,
  children: null,
  handleClickRow: () => {},
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  canFilterDate: PropTypes.bool,
  canExport: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  fetchData: PropTypes.func,
  fetchDataExport: PropTypes.func,
  dataExport: PropTypes.string,
  pageCount: PropTypes.number,
  children: PropTypes.node,
  handleClickRow: PropTypes.func,
};

export default DataTable;
