import { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import CardVehicleInsurances from "../vehicle/components/CardVehicleInsurances";

// MS
import service from "../../services/apiMS/index";

function ViewInsurances() {
  const [vehicleInsurances, setVehicleInsurances] = useState({});
  const [dataProcessing, setDataProcessing] = useState(false);
  const { vehicleId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const getVehicleInsurances = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setDataProcessing(true);

      const insurancesResponse = await service.getVehicleInsurances({ userData, vehicleId });

      if (!insurancesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (insurancesResponse.ok) {
        const insurancesInfoData = await insurancesResponse.json();
        setVehicleInsurances(insurancesInfoData);
      }
      setDataProcessing(false);
    };

    getVehicleInsurances();
  }, [navigate, vehicleId]);

  const insurances = vehicleInsurances.length > 0 ? [...vehicleInsurances] : [];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <MDTypography variant="h6" color="white">
                  Pólizas del vehículo
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={3}>
                {insurances.map((insurance) => (
                  <Grid key={insurance.id} item xs={10} md={10} lg={10}>
                    <CardVehicleInsurances
                      insuranceId={`${insurance.id}`}
                      insuranceCompanyName={`${insurance.insurance_company.name}`}
                      insuranceNumber={`${insurance.number}`}
                      imageCompany={`${insurance.insurance_company.logo_picture}`}
                      user={`${insurance.user_id}`}
                      licence={`${insurance.vehicle.licence_plate}`}
                      status={`${insurance.status}`}
                      start={`${insurance.start_at}`}
                      expire={`${insurance.expire_at}`}
                      insuranceCompany={`${insurance.insurance_company_id}`}
                    />
                  </Grid>
                ))}
              </MDBox>
              <MDBox mt={3}>
                <div align="center">
                  {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : ""}
                </div>
              </MDBox>
              <MDBox mb={3} px={5} display="flex" flexDirection="row-reverse">
                <Stack direction="row" spacing={2}>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    onClick={() => navigate(-1)}
                  >
                    <Icon>undo</Icon>&nbsp; volver
                  </MDButton>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewInsurances;
