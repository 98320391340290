import { useEffect, useCallback, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";

/* eslint-disable jsx-a11y/label-has-associated-control */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import AddPhotoIcon from "@mui/icons-material/AddAPhoto";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import moment from "moment";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// MS
import service from "../../services/apiMS/index";

import validator from "./validateUser";

const initialValues = {
  id: "",
  username: "",
  first_name: "",
  last_name: "",
  document_type_id: "",
  document_number: "",
  dob: "",
  email: "",
  address: "",
  phone: "",
  sex_id: "",
  tax_condition_id: "",
  city_id: "",
  state_id: "",
  profile_picture: null,
};

function FormUser() {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [docTypes, setDocTypes] = useState([]);
  const [gender, setGender] = useState([]);
  const [selectedGender, setSelectedGender] = useState({});
  const [taxCondition, setTaxCondition] = useState([]);
  const [selectedTaxCondition, setSelectedTaxCondition] = useState({});
  const [selectedDocType, setSelectedDocType] = useState({});
  const [dataProcessing, setDataProcessing] = useState(false);
  const [sendProfilePicture, setSendProfilePicture] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [showToastOk, setShowToastOk] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [saveError, setSaveError] = useState();
  const loading = dataProcessing && states.length === 0;

  const { handleSubmit, handleChange, values, resetForm, errors, setFieldValue } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const formValuesToSend = {
        ...formValues,
        profile_picture: sendProfilePicture ? formValues.profile_picture : undefined,
      };

      setSaveLoading(true);

      const saveUserResponse = await service.saveUser({ userData, formValues: formValuesToSend });

      if (saveUserResponse.ok) {
        setShowToastOk(true);
      } else {
        const errorSaveResponse = await saveUserResponse.json();
        setSaveError(errorSaveResponse.message);
        setShowToastError(true);
      }

      setSaveLoading(false);
    },
    validate: (v) => validator(v),
  });

  useEffect(() => {
    const getStates = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const statesResponse = await service.getStates({ userData });

      if (!statesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (statesResponse.ok) {
        const statesInfoData = await statesResponse.json();
        setStates(statesInfoData);
      }
      setDataProcessing(false);
    };
    getStates();
  }, [navigate]);

  useEffect(() => {
    const getCities = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const citiesResponse = await service.getCities({ userData, stateId: values.state_id });

      if (!citiesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (citiesResponse.ok) {
        const citiesInfoData = await citiesResponse.json();
        setCities(citiesInfoData.cities);
      }
      setDataProcessing(false);
    };

    if (values.state_id) {
      getCities();
    }
  }, [navigate, values.state_id]);

  useEffect(() => {
    const getDocTypes = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const docTypesResponse = await service.getDocTypes({ userData });

      if (!docTypesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (docTypesResponse.ok) {
        const docTypesInfoData = await docTypesResponse.json();
        setDocTypes(docTypesInfoData);
      }
      setDataProcessing(false);
    };

    getDocTypes();
  }, [navigate]);

  useEffect(() => {
    const getGenders = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const genderResponse = await service.getGenders({ userData });

      if (!genderResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (genderResponse.ok) {
        const genderInfoData = await genderResponse.json();
        setGender(genderInfoData);
      }
      setDataProcessing(false);
    };

    getGenders();
  }, [navigate]);

  useEffect(() => {
    const getTaxCondition = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const taxResponse = await service.getTaxCondition({ userData });

      if (!taxResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (taxResponse.ok) {
        const taxInfoData = await taxResponse.json();
        setTaxCondition(taxInfoData);
      }
      setDataProcessing(false);
    };

    getTaxCondition();
  }, [navigate]);

  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    const getUser = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const userResponse = await service.getUser({ userData, userId });

      if (!userResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (userResponse.ok) {
        const userInfoData = await userResponse.json();
        resetForm({
          values: {
            id: userInfoData.id,
            username: userInfoData.username,
            first_name: userInfoData.first_name,
            last_name: userInfoData.last_name,
            document_type_id: userInfoData.document_type_id,
            document_number: userInfoData.document_number,
            dob: userInfoData.dob,
            email: userInfoData.email,
            address: userInfoData.address,
            phone: userInfoData.phone,
            sex_id: userInfoData.sex_id,
            tax_condition_id: userInfoData.tax_condition_id,
            city_id: userInfoData.city_id,
            state_id: userInfoData.state_id,
            profile_picture: userInfoData.profile_picture,
          },
        });
      }
    };

    getUser();
  }, [navigate, resetForm, userId]);

  const statesAsJSON = JSON.stringify(states);

  useEffect(() => {
    const statesValue = JSON.parse(statesAsJSON);
    const foundState = statesValue.find((state) => state.id === values.state_id);
    setSelectedState(foundState);
  }, [statesAsJSON, values.state_id]);

  const citiesAsJSON = JSON.stringify(cities);

  useEffect(() => {
    const citiesValue = JSON.parse(citiesAsJSON);
    const foundCity = citiesValue.find((city) => city.id === values.city_id);
    setSelectedCity(foundCity);
  }, [citiesAsJSON, values.city_id]);

  const typesAsJSON = JSON.stringify(docTypes);

  useEffect(() => {
    const docTypeValue = JSON.parse(typesAsJSON);
    const foundType = docTypeValue.find((type) => type.id === values.document_type_id);
    setSelectedDocType(foundType);
  }, [typesAsJSON, values.document_type_id]);

  const genderAsJSON = JSON.stringify(gender);

  useEffect(() => {
    const genderValue = JSON.parse(genderAsJSON);
    const foundGender = genderValue.find((sex) => sex.id === values.sex_id);
    setSelectedGender(foundGender);
  }, [genderAsJSON, values.sex_id]);

  const taxConditionAsJSON = JSON.stringify(taxCondition);

  useEffect(() => {
    const taxConditionValue = JSON.parse(taxConditionAsJSON);
    const foundTaxCondition = taxConditionValue.find((tax) => tax.id === values.tax_condition_id);
    setSelectedTaxCondition(foundTaxCondition);
  }, [taxConditionAsJSON, values.tax_condition_id]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFieldValue("profile_picture", file);
      setSendProfilePicture(true);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Editar usuario
            </MDTypography>
          </MDBox>
          <form action="" className="form-container" onSubmit={handleSubmit}>
            {showToastOk && (
              <Collapse in={showToastOk}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setShowToastOk(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Usuario actualizado
                </Alert>
              </Collapse>
            )}
            {showToastError && (
              <Collapse in={showToastError}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setShowToastError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="error"
                  sx={{ mb: 2 }}
                >
                  {saveError}
                </Alert>
              </Collapse>
            )}
            <MDBox display="flex" mt={4} mb={6} mx={6} pt={3} py={3} sm={12} md={6} lg={4}>
              <MDBox display="flex" mr={2}>
                <Grid>
                  <div
                    {...getRootProps()}
                    className={`dropzone ${isDragActive ? "drag-active" : ""}`}
                  >
                    <input {...getInputProps()} />

                    <Box
                      component="img"
                      sx={{
                        height: 250,
                        width: 250,
                        maxHeight: { xs: 200, md: 250 },
                        maxWidth: { xs: 200, md: 250 },
                        p: 1,
                        border: "1px dashed grey",
                      }}
                      src={selectedImage ?? values.profile_picture}
                    />

                    <IconButton>
                      <AddPhotoIcon />
                    </IconButton>
                  </div>
                </Grid>
              </MDBox>
              <MDBox display="flex">
                <Grid container spacing={3}>
                  <Grid item sm={4} md={6}>
                    <TextField
                      label="Nombre"
                      variant="standard"
                      id="first_name"
                      name="first_name"
                      fullWidth
                      value={values.first_name}
                      onChange={handleChange}
                      error={errors.first_name}
                      helperText={errors.first_name}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Apellido"
                      variant="standard"
                      id="last_name"
                      name="last_name"
                      fullWidth
                      value={values.last_name}
                      onChange={handleChange}
                      error={errors.last_name}
                      helperText={errors.last_name}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="document_type_id"
                      name="document_type_id"
                      value={selectedDocType}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.value}
                      onChange={(_, value) => {
                        setFieldValue("document_type_id", value?.id);
                      }}
                      options={docTypes?.map((type) => ({
                        value: type.value,
                        id: type.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo Doc"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Documento"
                      variant="standard"
                      id="document_number"
                      name="document_number"
                      fullWidth
                      value={values.document_number}
                      onChange={handleChange}
                      error={errors.document_number}
                      helperText={errors.document_number}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Fecha de nacimiento (DD/MM/YYYY)"
                      variant="standard"
                      fullWidth
                      id="dob"
                      name="dob"
                      value={values.dob ? moment(values.dob).format("DD/MM/YYYY") : ""}
                      onChange={handleChange}
                      error={errors.dob}
                      helperText={errors.dob}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      id="sex_id"
                      name="sex_id"
                      value={selectedGender}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.value}
                      onChange={(_, value) => {
                        setFieldValue("sex_id", value?.id);
                      }}
                      options={gender?.map((sex) => ({
                        value: sex.value,
                        id: sex.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Género"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      id="tax_condition_id"
                      name="tax_condition_id"
                      value={selectedTaxCondition}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.value}
                      onChange={(_, value) => {
                        setFieldValue("tax_condition_id", value?.id);
                      }}
                      options={taxCondition?.map((tax) => ({
                        value: tax.value,
                        id: tax.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condición frente al IVA"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Email"
                      variant="standard"
                      fullWidth
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      error={errors.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Dirección"
                      variant="standard"
                      fullWidth
                      id="address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      error={errors.address}
                      helperText={errors.address}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Teléfono"
                      variant="standard"
                      fullWidth
                      id="phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      error={errors.phone}
                      helperText={errors.phone}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="state_id"
                      name="state_id"
                      value={selectedState}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) => {
                        setFieldValue("state_id", value?.id);
                      }}
                      options={states?.map((state) => ({
                        name: state.name,
                        id: state.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Provincia"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="city_id"
                      name="city_id"
                      value={selectedCity}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) => {
                        setFieldValue("city_id", value?.id);
                      }}
                      options={cities?.map((city) => ({
                        name: city.name,
                        id: city.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ciudad"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="row-reverse"
              mt={2}
              mb={2}
              mx={6}
              pt={3}
              py={3}
              px={2}
              sm={12}
              md={6}
              lg={8}
            >
              <Stack direction="row" spacing={2}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  onClick={() => navigate(`/user/${values.id}`)}
                >
                  <Icon>undo</Icon>&nbsp; volver
                </MDButton>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="success"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  onSubmit={handleSubmit}
                  sx={{ minWidth: "8rem" }}
                >
                  {saveLoading ? (
                    <CircularProgress color="inherit" size="1.2rem" />
                  ) : (
                    <>
                      <Icon>save</Icon>&nbsp; guardar
                    </>
                  )}
                </MDButton>
              </Stack>
            </MDBox>
          </form>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default FormUser;
