// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, /* useCallback */ useEffect } from "react";
// import { Document, Page, pdfjs } from "";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function InsuranceInfoCard({ title, pdfUrl, info, action, shadow }) {
  const labels = [];
  const values = [];
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();

  // const onDocumentLoadSuccess = useCallback(({ numPages: numPagesPDF }) => {
  //   setNumPages(numPagesPDF);
  //   setPageNumber(1);
  // }, []);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[a-z\s]+/)) {
      const lowercaseLetter = Array.from(el).find((i) => i.match(/[a-z]+/));
      const newElement = el.replace(lowercaseLetter, ` ${lowercaseLetter.toUpperCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={3}>
      <Grid item display="flex" flexDirection="row">
        <TextField label={label} value={values[key]} variant="standard" fullWidth />
      </Grid>
    </MDBox>
  ));

  useEffect(() => {
    const loadPdf = async () => {
      // Initialize PDF.js
      const pdfjsLib = window["pdfjs-dist/build/pdf"];
      const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
      // Fetch the first page
      const page = await pdf.getPage(pageNumber);
      const pageCount = pdf.numPages;

      setNumPages(pageCount);

      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      // Prepare the canvas element for rendering the PDF
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      document.getElementById("pdf-viewer").innerHTML = "";
      document.getElementById("pdf-viewer").appendChild(canvas);

      // Render the PDF page on the canvas
      const renderContext = {
        canvasContext: context,
        viewport,
      };
      page.render(renderContext);
    };

    loadPdf();
  }, [pageNumber, pdfUrl]);

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography component={Link} to={action.route} variant="body2" color="secondary">
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox p={2} mt={1}>
        <Grid container display="flex" flexDirection="row">
          {renderItems}
        </Grid>
      </MDBox>
      <MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDButton
            variant="gradient"
            color="success"
            size="small"
            iconOnly
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            <Icon>navigate_before</Icon>
          </MDButton>
          <MDButton size="small" disabled>
            {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
          </MDButton>
          <MDButton
            color="success"
            size="small"
            iconOnly
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            <Icon>navigate_next</Icon>
          </MDButton>
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDBox sx={{ border: 1 }}>
            <div id="pdf-viewer" />;
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox mt={1} mb={2} display="flex" flexDirection="row-reverse">
        <Stack direction="row" spacing={2}>
          <MDButton type="submit" variant="gradient" color="dark" onClick={() => navigate(-1)}>
            <Icon>undo</Icon>&nbsp; volver
          </MDButton>
        </Stack>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
InsuranceInfoCard.defaultProps = {
  pdfUrl: "",
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
InsuranceInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default InsuranceInfoCard;
