/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback, useEffect } from "react";
import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
// eslint-disable-next-line no-unused-vars
import buildInsurancesTableData from "layouts/insurances/data/insurancesTableData";
import { useNavigate } from "react-router-dom";

// MS
import service from "../../services/apiMS/index";

function Tables() {
  const [insurancesTableData, setInsurancesTableData] = useState({
    columns: [],
    rows: [],
  });

  const [pageCount, setPageCount] = useState(0);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [selectedInsurance, setSelectedInsurance] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedFilter, setSelectedFilter] = useState({});
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const navigate = useNavigate();

  const fetchData = useCallback(
    async ({ pageIndex }) => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      let createdAtFrom;
      let createdAtTo;
      let expireAtFrom;
      let expireAtTo;
      let startAtFrom;
      let startAtTo;

      if (selectedFilter?.id) {
        switch (selectedFilter.id) {
          case "created_at":
            createdAtFrom =
              dateFrom && dayjs(dateFrom).isValid()
                ? dayjs(dateFrom).format("YYYY-MM-DD")
                : undefined;
            createdAtTo =
              dateTo && dayjs(dateTo).isValid() ? dayjs(dateTo).format("YYYY-MM-DD") : undefined;
            break;
          case "start_at":
            startAtFrom =
              dateFrom && dayjs(dateFrom).isValid()
                ? dayjs(dateFrom).format("YYYY-MM-DD")
                : undefined;
            startAtTo =
              dateTo && dayjs(dateTo).isValid() ? dayjs(dateTo).format("YYYY-MM-DD") : undefined;
            break;
          case "expire_at":
            expireAtFrom =
              dateFrom && dayjs(dateFrom).isValid()
                ? dayjs(dateFrom).format("YYYY-MM-DD")
                : undefined;
            expireAtTo =
              dateTo && dayjs(dateTo).isValid() ? dayjs(dateTo).format("YYYY-MM-DD") : undefined;

            break;
          default:
            break;
        }
      }

      setDataProcessing(true);
      const insurancesResponse = await service.getInsurances({
        userData,
        pageIndex: pageIndex + 1,
        insurance: selectedInsurance?.id ? selectedInsurance : undefined,
        status: selectedStatus?.id ? selectedStatus : undefined,
        createdAtFrom,
        createdAtTo,
        expireAtFrom,
        expireAtTo,
        startAtFrom,
        startAtTo,
      });

      if (!insurancesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (insurancesResponse.ok) {
        const insurancesData = await insurancesResponse.json();
        const newInsurancesTableData = buildInsurancesTableData(insurancesData.data);
        setInsurancesTableData(newInsurancesTableData);
        setPageCount(insurancesData.last_page);
      }
      setDataProcessing(false);
    },
    [dateFrom, dateTo, navigate, selectedFilter.id, selectedInsurance, selectedStatus]
  );

  const fetchDataExport = useCallback(
    async ({ pageIndex }) => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      let createdAtFrom;
      let createdAtTo;
      let expireAtFrom;
      let expireAtTo;
      let startAtFrom;
      let startAtTo;

      if (selectedFilter?.id) {
        switch (selectedFilter.id) {
          case "created_at":
            createdAtFrom =
              dateFrom && dayjs(dateFrom).isValid()
                ? dayjs(dateFrom).format("YYYY-MM-DD")
                : undefined;
            createdAtTo =
              dateTo && dayjs(dateTo).isValid() ? dayjs(dateTo).format("YYYY-MM-DD") : undefined;
            break;
          case "start_at":
            startAtFrom =
              dateFrom && dayjs(dateFrom).isValid()
                ? dayjs(dateFrom).format("YYYY-MM-DD")
                : undefined;
            startAtTo =
              dateTo && dayjs(dateTo).isValid() ? dayjs(dateTo).format("YYYY-MM-DD") : undefined;
            break;
          case "expire_at":
            expireAtFrom =
              dateFrom && dayjs(dateFrom).isValid()
                ? dayjs(dateFrom).format("YYYY-MM-DD")
                : undefined;
            expireAtTo =
              dateTo && dayjs(dateTo).isValid() ? dayjs(dateTo).format("YYYY-MM-DD") : undefined;

            break;
          default:
            break;
        }
      }

      const exportResponse = await service.exportInsurances({
        userData,
        pageIndex: pageIndex + 1,
        insurance: selectedInsurance?.id ? selectedInsurance : undefined,
        status: selectedStatus?.id ? selectedStatus : undefined,
        createdAtFrom,
        createdAtTo,
        expireAtFrom,
        expireAtTo,
        startAtFrom,
        startAtTo,
      });

      const RESPONSE_STATUS_UNAUTHORIZED = 401;

      if (exportResponse.ok) {
        const dataResponseExport = await exportResponse.json();
        // eslint-disable-next-line consistent-return
        return dataResponseExport.file_url;
      }
      if (!exportResponse.status === RESPONSE_STATUS_UNAUTHORIZED) {
        navigate("/authentication/sign-in");
      } else {
        // eslint-disable-next-line consistent-return
        return undefined;
      }
    },
    [dateFrom, dateTo, navigate, selectedFilter.id, selectedInsurance, selectedStatus]
  );

  useEffect(() => {
    const getInsuranceCompanies = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const insurancesResponse = await service.getInsuranceCompanies({ userData });

      if (!insurancesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (insurancesResponse.ok) {
        const insurancesData = await insurancesResponse.json();
        setInsurances(insurancesData);
      }
    };

    getInsuranceCompanies();
  }, [navigate]);

  useEffect(() => {
    fetchData({ pageIndex: 0 });
  }, [fetchData, selectedInsurance, selectedStatus, dateFrom, dateTo]);

  const status = [
    { label: "Error de proveedor", id: "provider_error" },
    { label: "Error de usuario", id: "user_error" },
    { label: "Exitoso", id: "success" },
    { label: "Pendiente", id: "pending" },
  ];
  const filters = [
    { label: "Creación", id: "created_at" },
    { label: "Inicio Cobertura", id: "start_at" },
    { label: "Fin Cobertura", id: "expire_at" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Pólizas
            </MDTypography>
          </MDBox>

          <MDBox display="flex" mx={2} mt={-2} py={2} px={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} mt={3}>
                <Autocomplete
                  disablePortal
                  id="insurance_id"
                  size="small"
                  name="insurance_id"
                  value={selectedInsurance?.id ? selectedInsurance : null}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => {
                    const newSelectedInsurance = value?.id
                      ? insurances.find((insurance) => insurance.id === value.id)
                      : {};
                    setSelectedInsurance(newSelectedInsurance);
                  }}
                  options={insurances?.map((insurance) => ({
                    name: insurance.name,
                    id: insurance.id,
                  }))}
                  renderInput={(params) => <TextField {...params} label="Aseguradora" />}
                />
              </Grid>
              <Grid item xs={12} md={2} mt={3}>
                <Autocomplete
                  disablePortal
                  id="status"
                  size="small"
                  name="status"
                  value={selectedStatus?.id ? selectedStatus : null}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => option.label}
                  onChange={(_, value) => {
                    const newSelectedStatus = value?.id
                      ? status.find((state) => state.id === value.id)
                      : {};
                    setSelectedStatus(newSelectedStatus);
                  }}
                  options={status}
                  renderInput={(params) => <TextField {...params} label="Estado" />}
                />
              </Grid>
              <Grid item xs={12} md={2} mt={3}>
                <Autocomplete
                  disablePortal
                  id="filter"
                  size="small"
                  name="filter"
                  value={selectedFilter?.id ? selectedFilter : null}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => option.label}
                  onChange={(_, value) => {
                    const newSelectedFilter = value?.id
                      ? filters.find((state) => state.id === value.id)
                      : {};
                    setSelectedFilter(newSelectedFilter);
                  }}
                  options={filters}
                  renderInput={(params) => <TextField {...params} label="Filtro por fechas" />}
                />
              </Grid>

              {selectedFilter?.id && (
                <Grid item xs={12} md={6} mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker"]}
                      sx={{ overflow: "hidden" }}
                    >
                      <DatePicker
                        label="Fecha desde"
                        value={dateFrom}
                        format="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setDateFrom(newValue);
                        }}
                        slotProps={{ textField: { size: "small" } }}
                      />
                      <DatePicker
                        label="Fecha hasta"
                        value={dateTo}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { size: "small" } }}
                        onChange={(newValue) => {
                          setDateTo(newValue);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              )}
            </Grid>
          </MDBox>

          <MDBox>
            {insurancesTableData && (
              <DataTable
                canSearch={false}
                canFilterDate={false}
                table={{
                  columns: insurancesTableData.columns,
                  rows: insurancesTableData.rows,
                }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                fetchData={fetchData}
                fetchDataExport={fetchDataExport}
                pageCount={pageCount}
                noEndBorder
                handleClickRow={(row) => {
                  const id = row.values.id.props.children;
                  window.location.href = `/insurance/${id}`;
                }}
              />
            )}
            <MDBox pb={6}>
              <div align="center">
                {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : ""}
              </div>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
