import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

/* eslint-disable jsx-a11y/label-has-associated-control */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// MS
import service from "../../services/apiMS/index";

import validator from "./validateVehicle";

const initialValues = {
  id: "",
  brand: "",
  model: "",
  year: "",
  engine_number: "",
  contract_id: "",
  category: "",
  chassis_number: "",
  licence_plate: "",
  insurance_company: "",
  lienholder: "",
  use_type: "",
  address: "",
  state_id: "",
  city_id: "",
  user: "",
  userId: "",
};

function FormVehicle() {
  const { vehicleId } = useParams();
  const navigate = useNavigate();
  const [saveResponse, setSaveResponse] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [typesUse, setTypesUse] = useState([]);
  const [selectedTypeUse, setSelectedTypeUse] = useState({});
  const [dataProcessing, setDataProcessing] = useState(false);
  const [open, setOpen] = useState(true);
  const loading = dataProcessing && states.length === 0;

  const { handleSubmit, handleChange, values, resetForm, errors, setFieldValue } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const saveVehicleResponse = await service.saveVehicle({ userData, formValues });

      if (saveVehicleResponse.ok) {
        setSaveResponse(true);
        setOpen(true);
      }
    },
    validate: (v) => validator(v),
  });

  useEffect(() => {
    const getVehicle = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const vehicleResponse = await service.getVehicle({ userData, vehicleId });

      if (!vehicleResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (vehicleResponse.ok) {
        const vehicleInfoData = await vehicleResponse.json();
        resetForm({
          values: {
            id: vehicleInfoData.id,
            brand: vehicleInfoData.brand,
            model: vehicleInfoData.model,
            year: vehicleInfoData.year,
            engine_number: vehicleInfoData.engine_number,
            contract_id: vehicleInfoData.contract_id,
            category: vehicleInfoData.category,
            chassis_number: vehicleInfoData.chassis_number,
            licence_plate: vehicleInfoData.licence_plate,
            insurance_company: vehicleInfoData.insurance_company?.name,
            insurance_company_id: vehicleInfoData.insurance_company?.id,
            lienholder: vehicleInfoData.lienholder?.name,
            lienholder_id: vehicleInfoData.lienholder?.id,
            use_type: vehicleInfoData.vehicle_use_type?.value,
            use_type_id: vehicleInfoData.vehicle_use_type?.key,
            address: vehicleInfoData.address,
            state_id: vehicleInfoData.state_id,
            city_id: vehicleInfoData.city_id,
            user: `${vehicleInfoData.user?.first_name} ${vehicleInfoData.user?.last_name}`,
            user_id: vehicleInfoData.user.id,
          },
        });
      }
    };

    getVehicle();
  }, [navigate, resetForm, vehicleId]);

  useEffect(() => {
    const getStates = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const statesResponse = await service.getStates({ userData });

      if (!statesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (statesResponse.ok) {
        const statesInfoData = await statesResponse.json();
        setStates(statesInfoData);
      }
      setDataProcessing(false);
    };
    getStates();
  }, [navigate]);

  useEffect(() => {
    const getTypesUse = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const typeResponse = await service.getTypesUse({ userData });

      if (!typeResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (typeResponse.ok) {
        const typeInfoData = await typeResponse.json();
        setTypesUse(typeInfoData);
      }
      setDataProcessing(false);
    };
    getTypesUse();
  }, [navigate]);

  useEffect(() => {
    const getCities = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const citiesResponse = await service.getCities({ userData, stateId: values.state_id });

      if (!citiesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (citiesResponse.ok) {
        const citiesInfoData = await citiesResponse.json();
        setCities(citiesInfoData.cities);
      }
      setDataProcessing(false);
    };

    if (values.state_id) {
      getCities();
    }
  }, [navigate, values.state_id]);

  useEffect(() => {
    const foundState = states.find((state) => state.id === +values.state_id);
    setSelectedState(foundState);
  }, [JSON.stringify(states), values.state_id]);

  useEffect(() => {
    const foundCity = cities.find((city) => city.id === +values.city_id);
    setSelectedCity(foundCity);
  }, [JSON.stringify(cities), values.city_id]);

  useEffect(() => {
    const foundType = typesUse.find((type) => type.value === values.use_type);
    setSelectedTypeUse(foundType);
  }, [JSON.stringify(typesUse), values.use_type]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Editar vehículo
            </MDTypography>
          </MDBox>
          <form action="" className="form-container" onSubmit={handleSubmit}>
            {saveResponse === true && (
              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Vehículo actualizado
                </Alert>
              </Collapse>
            )}
            <MDBox mt={4} mb={6} mx={6} pt={3} py={3} px={2}>
              <Grid container spacing={4} display="flex" flexDirection="row">
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Marca"
                    variant="standard"
                    id="brand"
                    name="brand"
                    value={values.brand}
                    onChange={handleChange}
                    error={errors.brand}
                    helperText={errors.brand}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Modelo"
                    variant="standard"
                    id="model"
                    name="model"
                    value={values.model}
                    onChange={handleChange}
                    error={errors.model}
                    helperText={errors.model}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Año"
                    variant="standard"
                    id="year"
                    name="year"
                    value={values.year}
                    onChange={handleChange}
                    error={errors.year}
                    helperText={errors.year}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Patente"
                    variant="standard"
                    id="licence_plate"
                    name="licence_plate"
                    value={values.licence_plate}
                    onChange={handleChange}
                    error={errors.licence_plate}
                    helperText={errors.licence_plate}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Motor"
                    variant="standard"
                    id="engine_number"
                    name="engine_number"
                    value={values.engine_number}
                    onChange={handleChange}
                    error={errors.engine_number}
                    helperText={errors.engine_number}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Acreedor"
                    variant="standard"
                    id="lienholder"
                    name="lienholder"
                    value={values.lienholder}
                    onChange={handleChange}
                    error={errors.lienholder}
                    helperText={errors.lienholder}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Aseguradora"
                    variant="standard"
                    id="insurance_company"
                    name="insurance_company"
                    value={values.insurance_company}
                    onChange={handleChange}
                    error={errors.insurance_company}
                    helperText={errors.insurance_company}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Contrato"
                    variant="standard"
                    id="contract_id"
                    name="contract_id"
                    value={values.contract_id}
                    onChange={handleChange}
                    error={errors.contract}
                    helperText={errors.contract_id}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Categoría"
                    variant="standard"
                    id="category"
                    name="category"
                    value={values.category}
                    onChange={handleChange}
                    error={errors.category}
                    helperText={errors.category}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Nro. Chasis"
                    variant="standard"
                    id="chassis_number"
                    name="chassis_number"
                    value={values.chassis_number}
                    onChange={handleChange}
                    error={errors.chassis}
                    helperText={errors.chassis}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} marginTop={0.8}>
                  <Autocomplete
                    disablePortal
                    id="use_type"
                    name="use_type"
                    value={selectedTypeUse}
                    isOptionEqualToValue={(option, value) => option?.key === value?.key}
                    getOptionLabel={(option) => option.value}
                    onChange={(_, value) => {
                      setFieldValue("use_type", value?.value);
                    }}
                    options={typesUse?.map((type) => ({
                      value: type.value,
                      key: type.key,
                    }))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de uso"
                        variant="standard"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        error={errors.use_type}
                        helperText={errors.use_type}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Dirección"
                    variant="standard"
                    id="address"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    error={errors.address}
                    helperText={errors.address}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    disablePortal
                    id="state_id"
                    name="state_id"
                    value={selectedState}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => {
                      setFieldValue("state_id", value?.id);
                    }}
                    options={states?.map((state) => ({
                      name: state.name,
                      id: state.id,
                    }))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Provincia"
                        variant="standard"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        error={errors.state_id}
                        helperText={errors.state_id}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    disablePortal
                    id="city_id"
                    name="city_id"
                    value={selectedCity}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => {
                      setFieldValue("city_id", value?.id);
                    }}
                    options={cities?.map((city) => ({
                      name: city.name,
                      id: city.id,
                    }))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ciudad"
                        variant="standard"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        error={errors.city_id}
                        helperText={errors.city_id}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Usuario"
                    variant="standard"
                    id="user"
                    name="user"
                    value={values.user}
                    onChange={handleChange}
                    error={errors.user}
                    helperText={errors.user}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <MDBox mt={6} mb={1} display="flex" flexDirection="row-reverse">
                <Stack direction="row" spacing={2}>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="dark"
                    onClick={() => navigate(`/vehicle/${values.id}`)}
                  >
                    <Icon>undo</Icon>&nbsp; volver
                  </MDButton>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="success"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="dark"
                    onSubmit={handleSubmit}
                  >
                    <Icon>save</Icon>&nbsp; guardar
                  </MDButton>
                </Stack>
              </MDBox>
            </MDBox>
          </form>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default FormVehicle;
