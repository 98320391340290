import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";

export default function data(companiesData) {
  return {
    columns: [
      { Header: "aseguradora", accessor: "companies", width: "45%", align: "left" },
      { Header: "usuarios", accessor: "users", align: "center" },
      { Header: "vehículos", accessor: "vehicles", align: "center" },
      { Header: "pólizas", accessor: "insurances", align: "center" },
    ],

    // eslint-disable-next-line no-shadow
    rows: companiesData.map((data) => ({
      companies: (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar src={data.logo_picture} name={data.name} variant="square" size="sm" />
          <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
            {data.name}
          </MDTypography>
        </MDBox>
      ),
      users: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.users_count}
        </MDTypography>
      ),
      vehicles: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.vehicles_count}
        </MDTypography>
      ),
      insurances: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.insurance_documents_count}
        </MDTypography>
      ),
    })),
  };
}
