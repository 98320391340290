import MDTypography from "components/MDTypography";

import moment from "moment";

// eslint-disable-next-line no-unused-vars
export default function data(rolesData) {
  return {
    columns: [
      { Header: "id", accessor: "id", align: "left" },
      { Header: "nombre", accessor: "name", align: "left" },
      { Header: "creación", accessor: "created_at", align: "center" },
      { Header: "actualización", accessor: "updated_at", align: "center" },
    ],

    // eslint-disable-next-line no-shadow, no-unused-vars
    rows: rolesData.map((data) => ({
      id: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.id}
        </MDTypography>
      ),
      name: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.name}
        </MDTypography>
      ),
      created_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.created_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      updated_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.updated_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),
    })),
  };
}
