/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
// eslint-disable-next-line no-unused-vars
import buildVehicleTableData from "layouts/vehicles/data/vehiclesTableData";
import { useNavigate } from "react-router-dom";

// MS
import service from "../../services/apiMS/index";

function Tables() {
  // const { columns, rows } = authorsTableData();
  // eslint-disable-next-line no-unused-vars
  const [vehicleTableData, setVehicleTableData] = useState({
    columns: [],
    rows: [],
  });

  const [pageCount, setPageCount] = useState(0);
  const [dataProcessing, setDataProcessing] = useState(false);

  const navigate = useNavigate();

  const fetchData = useCallback(
    async ({ pageIndex, search, dateFrom, dateTo, sortBy }) => {
      const sort = sortBy?.[0]?.id;
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      setDataProcessing(true);
      const vehiclesResponse = await service.getVehicles({
        userData,
        pageIndex: pageIndex + 1,
        search,
        dateFrom,
        dateTo,
        sort,
      });

      if (!vehiclesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (vehiclesResponse.ok) {
        const vehiclesData = await vehiclesResponse.json();
        const newVehicleTableData = buildVehicleTableData(vehiclesData.data);
        setVehicleTableData(newVehicleTableData);
        setPageCount(vehiclesData.last_page);
      }
      setDataProcessing(false);
    },
    [navigate]
  );

  const fetchDataExport = useCallback(
    async ({ pageIndex, search, dateFrom, dateTo, sortBy }) => {
      const sort = sortBy?.[0]?.id;
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const exportResponse = await service.exportVehicles({
        userData,
        pageIndex: pageIndex + 1,
        search,
        dateFrom,
        dateTo,
        sort,
      });

      const RESPONSE_STATUS_UNAUTHORIZED = 401;

      if (exportResponse.ok) {
        const dataResponseExport = await exportResponse.json();
        // eslint-disable-next-line consistent-return
        return dataResponseExport.file_url;
      }
      if (!exportResponse.status === RESPONSE_STATUS_UNAUTHORIZED) {
        navigate("/authentication/sign-in");
      } else {
        // eslint-disable-next-line consistent-return
        return undefined;
      }
    },
    [navigate]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <MDTypography variant="h6" color="white">
                  Vehículos
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                {vehicleTableData && (
                  <DataTable
                    canSearch
                    table={{
                      columns: vehicleTableData.columns,
                      rows: vehicleTableData.rows,
                    }}
                    isSorted
                    entriesPerPage={false}
                    showTotalEntries={false}
                    fetchData={fetchData}
                    fetchDataExport={fetchDataExport}
                    pageCount={pageCount}
                    noEndBorder
                    handleClickRow={(row) => {
                      const id = row.values.id.props.children;
                      window.location.href = `/vehicle/${id}`;
                    }}
                  />
                )}
                <MDBox pb={6}>
                  <div align="center">
                    {dataProcessing ? <CircularProgress color="inherit" size="1.5rem" /> : ""}
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
