const BASE_URL = process.env.REACT_APP_BASE_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

const login = async (user, password) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username: user,
      password,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    }),
  };
  const loginResponse = await fetch(`${BASE_URL}/auth/user/login`, requestOptions);
  return loginResponse;
};

const getUsers = async ({ userData, pageIndex, search, dateFrom, dateTo }) => {
  const params = {};

  if (search) {
    params.search = `&search=${search}`;
  } else {
    params.search = "";
  }
  if (dateFrom) {
    params.date_from = `&date_from=${dateFrom}`;
  } else {
    params.date_from = "";
  }
  if (dateTo) {
    params.date_to = `&date_to=${dateTo}`;
  } else {
    params.date_to = "";
  }
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const usersResponse = await fetch(
    `${BASE_URL}/cms/users?page=${pageIndex}${params.search}${params.date_from}${params.date_to}`,
    requestOptions
  );
  return usersResponse;
};

const getUser = async ({ userData, userId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const userResponse = await fetch(`${BASE_URL}/cms/users/${userId}`, requestOptions);
  return userResponse;
};

const deleteUser = async ({ userData, userId }) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const userDeleteResponse = await fetch(`${BASE_URL}/cms/users/${userId}`, requestOptions);
  return userDeleteResponse;
};

const getGenders = async ({ userData }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };

  const gendersResponse = await fetch(`${BASE_URL}/cms/users/sexes`, requestOptions);
  return gendersResponse;
};

const getTaxCondition = async ({ userData }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const taxConditionResponse = await fetch(`${BASE_URL}/cms/users/tax_conditions`, requestOptions);
  return taxConditionResponse;
};

const getVehicles = async ({ userData, pageIndex, search, sort, dateFrom, dateTo }) => {
  const params = {};
  if (search) {
    params.search = `&search=${search}`;
  } else {
    params.search = "";
  }
  if (dateFrom) {
    params.date_from = `&date_from=${dateFrom}`;
  } else {
    params.date_from = "";
  }
  if (dateTo) {
    params.date_to = `&date_to=${dateTo}`;
  } else {
    params.date_to = "";
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const sortByParam = sort ? `&sortBy=${sort}` : "";
  const sortDirection = sort ? `&sortDirection=asc` : "";
  const vehiclesResponse = await fetch(
    `${BASE_URL}/cms/vehicles?page=${pageIndex}${sortByParam}${sortDirection}${params.search}${params.date_from}${params.date_to}`,
    requestOptions
  );
  return vehiclesResponse;
};

const getVehicle = async ({ userData, vehicleId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const vehicleResponse = await fetch(`${BASE_URL}/cms/vehicles/${vehicleId}`, requestOptions);
  return vehicleResponse;
};

const saveUser = async ({ userData, formValues }) => {
  const userId = formValues.id;
  const formdata = new FormData();

  formdata.append("document_number", formValues.document_number);
  formdata.append("first_name", formValues.first_name);
  formdata.append("last_name", formValues.last_name);
  formdata.append("username", formValues.username);
  formdata.append("sex_id", formValues.sex_id);
  if (formValues.tax_condition_id) {
    formdata.append("tax_condition_id", formValues.tax_condition_id);
  }
  formdata.append("phone", formValues.phone);
  formdata.append("email", formValues.email);
  formdata.append("dob", formValues.dob);
  formdata.append("address", formValues.address);
  formdata.append("state_id", formValues.state_id);
  formdata.append("city_id", formValues.city_id);
  if (formValues.document_type_id) {
    formdata.append("document_type_id", formValues.document_type_id);
  }
  if (formValues.profile_picture) {
    formdata.append("profile_picture", formValues.profile_picture);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
    body: formdata,
  };
  const saveResponse = await fetch(`${BASE_URL}/cms/users/${userId}`, requestOptions);
  return saveResponse;
};

const exportUsers = async ({ userData, pageIndex, search, dateFrom, dateTo }) => {
  const params = {};

  if (search) {
    params.search = `&search=${search}`;
  } else {
    params.search = "";
  }
  if (dateFrom) {
    params.date_from = `&date_from=${dateFrom}`;
  } else {
    params.date_from = "";
  }
  if (dateTo) {
    params.date_to = `&date_to=${dateTo}`;
  } else {
    params.date_to = "";
  }

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const exportResponse = await fetch(
    `${BASE_URL}/cms/users/export?page=${pageIndex}${params.search}${params.date_from}${params.date_to}`,
    requestOptions
  );
  return exportResponse;
};

const exportVehicles = async ({ userData, pageIndex, search, dateFrom, dateTo }) => {
  const params = {};

  if (search) {
    params.search = `&search=${search}`;
  } else {
    params.search = "";
  }
  if (dateFrom) {
    params.date_from = `&date_from=${dateFrom}`;
  } else {
    params.date_from = "";
  }
  if (dateTo) {
    params.date_to = `&date_to=${dateTo}`;
  } else {
    params.date_to = "";
  }

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const exportResponse = await fetch(
    `${BASE_URL}/cms/vehicles/export?page=${pageIndex}${params.search}${params.date_from}${params.date_to}`,
    requestOptions
  );
  return exportResponse;
};

const exportInsurances = async ({
  userData,
  pageIndex,
  insurance,
  status,
  createdAtFrom,
  createdAtTo,
  expireAtFrom,
  expireAtTo,
  startAtFrom,
  startAtTo,
}) => {
  const params = {};

  if (insurance) {
    params.insurance_company_id = `&insurance_company_id=${insurance.id}`;
  } else {
    params.insurance_company_id = "";
  }
  if (status) {
    params.selectedStatus = `&status=${status.id}`;
  } else {
    params.selectedStatus = "";
  }

  if (createdAtFrom) {
    params.createdAtFrom = `&created_at_from=${createdAtFrom}`;
  } else {
    params.createdAtFrom = "";
  }

  if (createdAtTo) {
    params.createdAtTo = `&created_at_to=${createdAtTo}`;
  } else {
    params.createdAtTo = "";
  }

  if (expireAtFrom) {
    params.expireAtFrom = `&expire_at_from=${expireAtFrom}`;
  } else {
    params.expireAtFrom = "";
  }

  if (expireAtTo) {
    params.expireAtTo = `&expire_at_to=${expireAtTo}`;
  } else {
    params.expireAtTo = "";
  }

  if (startAtFrom) {
    params.startAtFrom = `&start_at_from=${startAtFrom}`;
  } else {
    params.startAtFrom = "";
  }

  if (startAtTo) {
    params.startAtTo = `&start_at_to=${startAtTo}`;
  } else {
    params.startAtTo = "";
  }

  const exportInsuranceRequestParams = [
    `page=${pageIndex}`,
    params.insurance_company_id,
    params.selectedStatus,
    params.createdAtFrom,
    params.createdAtTo,
    params.expireAtFrom,
    params.expireAtTo,
    params.startAtFrom,
    params.startAtTo,
  ].join("");

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const exportResponse = await fetch(
    `${BASE_URL}/cms/insurance_documents/export?${exportInsuranceRequestParams}`,
    requestOptions
  );
  return exportResponse;
};

const saveVehicle = async ({ userData, formValues }) => {
  const vehicleId = formValues.id;
  const formdata = new FormData();
  formdata.append("brand", formValues.brand);
  formdata.append("model", formValues.model);
  formdata.append("year", formValues.year);
  formdata.append("licence_plate", formValues.licence_plate);
  formdata.append("engine_number", formValues.engine_number);
  formdata.append("lienholder_id", formValues.lienholder_id);
  if (formValues.insurance_company_id) {
    formdata.append("insurance_company_id", formValues.insurance_company_id);
  }
  formdata.append("contract_id", formValues.contract_id);
  formdata.append("category", formValues.category);
  formdata.append("chassis_number", formValues.chassis_number);
  if (formValues.use_type_id) {
    formdata.append("use_type", formValues.use_type_id);
  }
  formdata.append("address", formValues.address);
  formdata.append("state_id", formValues.state_id);
  formdata.append("city_id", formValues.city_id);
  formdata.append("user_id", formValues.user_id);

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userData.access_token}`,
    },
    body: formdata,
  };
  const saveResponse = await fetch(`${BASE_URL}/cms/vehicles/${vehicleId}`, requestOptions);
  return saveResponse;
};

const getInsurances = async ({
  userData,
  pageIndex,
  insurance,
  status,
  createdAtFrom,
  createdAtTo,
  expireAtFrom,
  expireAtTo,
  startAtFrom,
  startAtTo,
}) => {
  const params = {};
  if (insurance) {
    params.insurance_company_id = `&insurance_company_id=${insurance.id}`;
  } else {
    params.insurance_company_id = "";
  }
  if (status) {
    params.selectedStatus = `&status=${status.id}`;
  } else {
    params.selectedStatus = "";
  }

  if (createdAtFrom) {
    params.createdAtFrom = `&created_at_from=${createdAtFrom}`;
  } else {
    params.createdAtFrom = "";
  }

  if (createdAtTo) {
    params.createdAtTo = `&created_at_to=${createdAtTo}`;
  } else {
    params.createdAtTo = "";
  }

  if (expireAtFrom) {
    params.expireAtFrom = `&expire_at_from=${expireAtFrom}`;
  } else {
    params.expireAtFrom = "";
  }

  if (expireAtTo) {
    params.expireAtTo = `&expire_at_to=${expireAtTo}`;
  } else {
    params.expireAtTo = "";
  }

  if (startAtFrom) {
    params.startAtFrom = `&start_at_from=${startAtFrom}`;
  } else {
    params.startAtFrom = "";
  }

  if (startAtTo) {
    params.startAtTo = `&start_at_to=${startAtTo}`;
  } else {
    params.startAtTo = "";
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const getInsuranceRequestParams = [
    `page=${pageIndex}`,
    params.insurance_company_id,
    params.selectedStatus,
    params.createdAtFrom,
    params.createdAtTo,
    params.expireAtFrom,
    params.expireAtTo,
    params.startAtFrom,
    params.startAtTo,
  ].join("");

  const insurancesResponse = await fetch(
    `${BASE_URL}/cms/insurance_documents?${getInsuranceRequestParams}`,
    requestOptions
  );
  return insurancesResponse;
};

const getInsurance = async ({ userData, insuranceId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const insuranceResponse = await fetch(
    `${BASE_URL}/cms/insurance_documents/${insuranceId}`,
    requestOptions
  );
  return insuranceResponse;
};

const getInsuranceCompanies = async ({ userData }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const insuranceResponse = await fetch(`${BASE_URL}/cms/insurance_companies`, requestOptions);
  return insuranceResponse;
};

const getUserInsurance = async ({ userData, userId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const insuranceResponse = await fetch(
    `${BASE_URL}/cms/users/${userId}/insurance_documents`,
    requestOptions
  );
  return insuranceResponse;
};

const saveInsurance = async ({ userData, formValues }) => {
  const insuranceId = formValues.insurance_id;
  const formdata = new FormData();

  formdata.append("insurance_company.name", formValues.insurance_company_name);
  formdata.append("name", formValues.insurance_name);
  formdata.append("number", formValues.insurance_number);
  formdata.append("status", formValues.insurance_status);
  formdata.append("engine_number", formValues.engine_number);
  formdata.append("lienholder_id", formValues.lienholder_id);
  formdata.append("insurance_company_id", formValues.insurance_company_id);
  formdata.append("contract", formValues.contract);

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userData.access_token}`,
    },
    body: formdata,
  };
  const saveResponse = await fetch(
    `${BASE_URL}/cms/insurance_documents/${insuranceId}`,
    requestOptions
  );
  return saveResponse;
};

const getRoles = async ({ userData, pageIndex }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const rolesResponse = await fetch(`${BASE_URL}/cms/roles?page=${pageIndex}`, requestOptions);
  return rolesResponse;
};

const getStates = async ({ userData }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const statesResponse = await fetch(`${BASE_URL}/cms/states`, requestOptions);
  return statesResponse;
};

const getCities = async ({ userData, stateId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const citiesResponse = await fetch(`${BASE_URL}/cms/states/${stateId}`, requestOptions);
  return citiesResponse;
};

const getTypesUse = async ({ userData }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const typeResponse = await fetch(`${BASE_URL}/cms/vehicles/use_types`, requestOptions);
  return typeResponse;
};

const getReportUsers = async ({ userData, periodUsers }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const typeResponse = await fetch(
    `${BASE_URL}/cms/dashboard/users/grouped/${periodUsers}/count`,
    requestOptions
  );
  return typeResponse;
};

const getReportVehicles = async ({ userData, periodVehicles }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const typeResponse = await fetch(
    `${BASE_URL}/cms/dashboard/vehicles/grouped/${periodVehicles}/count`,
    requestOptions
  );
  return typeResponse;
};

const getReportInsurances = async ({ userData, periodInsurances }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const typeResponse = await fetch(
    `${BASE_URL}/cms/dashboard/insurance_documents/grouped/${periodInsurances}/count`,
    requestOptions
  );
  return typeResponse;
};

const getReportCompanies = async ({ userData }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const typeResponse = await fetch(`${BASE_URL}/cms/dashboard/insurance_companies`, requestOptions);
  return typeResponse;
};

const getVehicleInsurances = async ({ userData, vehicleId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const vehicleInsurancesResponse = await fetch(
    `${BASE_URL}/cms/vehicles/${vehicleId}/insurance_documents`,
    requestOptions
  );
  return vehicleInsurancesResponse;
};

const getTips = async ({ userData, pageIndex }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const vehicleInsurancesResponse = await fetch(
    `${BASE_URL}/cms/tips?page=${pageIndex}`,
    requestOptions
  );
  return vehicleInsurancesResponse;
};

const getTip = async ({ userData, tipId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const vehicleInsurancesResponse = await fetch(`${BASE_URL}/cms/tips/${tipId}`, requestOptions);
  return vehicleInsurancesResponse;
};
const getDocTypes = async ({ userData }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const docTypesResponse = await fetch(`${BASE_URL}/cms/users/document_types`, requestOptions);
  return docTypesResponse;
};

const deleteTip = async ({ userData, tipId }) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const deleteTipResponse = await fetch(`${BASE_URL}/cms/tips/${tipId}`, requestOptions);
  return deleteTipResponse;
};

const getCategoriesTip = async ({ userData }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.access_token}`,
    },
  };
  const categoriesResponse = await fetch(`${BASE_URL}/cms/tips/categories`, requestOptions);
  return categoriesResponse;
};

const saveTip = async ({ userData, formValues }) => {
  const tipId = formValues.id;
  const formdata = new FormData();

  formdata.append("category_id", formValues.category_id);
  formdata.append("title", formValues.title);
  formdata.append("description", formValues.description);
  formdata.append("body", formValues.body);
  if (formValues.image) {
    formdata.append("image", formValues.image);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userData.access_token}`,
    },
    body: formdata,
  };
  const saveResponse = await fetch(`${BASE_URL}/cms/tips/${tipId}`, requestOptions);
  return saveResponse;
};

const createTip = async ({ userData, formValues }) => {
  const formdata = new FormData();

  formdata.append("category_id", formValues.category_id);
  formdata.append("title", formValues.title);
  formdata.append("description", formValues.description);
  formdata.append("body", formValues.body);
  if (formValues.image) {
    formdata.append("image", formValues.image);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userData.access_token}`,
    },
    body: formdata,
  };
  const createResponse = await fetch(`${BASE_URL}/cms/tips`, requestOptions);
  return createResponse;
};

const sendMailRecovery = async (email) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    }),
  };
  const recoveryResponse = await fetch(`${BASE_URL}/cms/users/password/email`, requestOptions);
  return recoveryResponse;
};

const sendCode = async (code) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      code,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    }),
  };
  const sendCodeResponse = await fetch(`${BASE_URL}/cms/users/password/code/check`, requestOptions);
  return sendCodeResponse;
};

const sendNewPassword = async (code, password, confirmPassword) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      code,
      password,
      password_confirmation: confirmPassword,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    }),
  };
  const sendNewPasswordResponse = await fetch(
    `${BASE_URL}/cms/users/password/reset`,
    requestOptions
  );
  return sendNewPasswordResponse;
};

const service = {
  login,
  getUsers,
  getUser,
  deleteUser,
  getVehicles,
  getVehicle,
  getInsurances,
  getUserInsurance,
  getVehicleInsurances,
  getInsurance,
  getInsuranceCompanies,
  getRoles,
  getGenders,
  getTaxCondition,
  getStates,
  getCities,
  getTypesUse,
  getReportUsers,
  getReportVehicles,
  getReportInsurances,
  getReportCompanies,
  getTips,
  getTip,
  getDocTypes,
  getCategoriesTip,
  deleteTip,
  saveUser,
  exportUsers,
  exportVehicles,
  exportInsurances,
  saveVehicle,
  saveInsurance,
  saveTip,
  createTip,
  sendMailRecovery,
  sendCode,
  sendNewPassword,
};

export default service;
