import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import InsuranceInfoCard from "examples/Cards/InfoCards/InsuranceInfoCard";

// Overview page components
import Header from "layouts/vehicle/components/InfoVehicle";

// MS
import service from "../../services/apiMS/index";

function Insurance() {
  const [insuranceInfo, setInsuranceInfo] = useState({});
  const [dataProcessing, setDataProcessing] = useState(false);
  const { insuranceId } = useParams();
  const navigate = useNavigate();

  const usuario =
    insuranceInfo.user?.first_name && insuranceInfo.user?.last_name
      ? `${insuranceInfo.user?.first_name} ${insuranceInfo.user?.last_name}`
      : "Sin datos";

  useEffect(() => {
    const getInsurance = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const insuranceResponse = await service.getInsurance({ userData, insuranceId });

      if (!insuranceResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (insuranceResponse.ok) {
        const insuranceInfoData = await insuranceResponse.json();
        setInsuranceInfo(insuranceInfoData);
      }
      setDataProcessing(false);
    };

    getInsurance();
  }, []);

  const hasInsuranceInfo = insuranceInfo.id;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header>
        <MDBox mt={1}>
          {dataProcessing && (
            <MDBox mt={4} pb={6}>
              <div align="center">
                <CircularProgress color="inherit" size="1.5rem" />
              </div>
            </MDBox>
          )}
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={12} xl={12}>
              {hasInsuranceInfo && (
                <InsuranceInfoCard
                  title="Detalle de la Póliza"
                  pdfUrl={insuranceInfo.url}
                  info={{
                    aseguradora: `${insuranceInfo.insurance_company.name}`,
                    nombre: `${insuranceInfo.name}`,
                    número: `${insuranceInfo.number}`,
                    estado: `${insuranceInfo.status}`,
                    marca: `${insuranceInfo.vehicle?.brand ?? "Sin datos"}`,
                    modelo: `${insuranceInfo.vehicle?.model ?? "Sin datos"}`,
                    patente: `${insuranceInfo.vehicle?.licence_plate ?? "Sin datos"}`,
                    contrato: `${insuranceInfo.vehicle?.contract_id ?? "Sin datos"}`,
                    creación: insuranceInfo.created_at
                      ? `${moment(insuranceInfo.created_at).format("DD/MM/YYYY - HH:MM:ss")}`
                      : "Sin datos",
                    inicio: insuranceId.start_at
                      ? `${moment(insuranceInfo.start_at).format("DD/MM/YYYY - HH:MM:ss")}`
                      : "Sin datos",
                    fin: insuranceInfo.expire_at
                      ? `${moment(insuranceInfo.expire_at).format("DD/MM/YYYY - HH:MM:ss")}`
                      : "Sin datos",
                    usuario: `${usuario}`,
                    documento: `${insuranceInfo.user?.document_number ?? "Sin datos"}`,
                  }}
                  action={{ route: `/forminsurance/${insuranceInfo.id}`, tooltip: "Editar Póliza" }}
                  shadow={false}
                />
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Insurance;
