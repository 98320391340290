import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import moment from "moment";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function VehicleInsurances({
  insuranceCompanyName,
  insuranceNumber,
  imageCompany,
  licence,
  status,
  start,
  expire,
  noGutter,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      mb={noGutter ? 0 : 1}
      px={2}
      pt={2}
      pb={2}
      mt={2}
      mx={5}
    >
      <Card sx={{ width: 600, display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
        <CardMedia component="img" alt="green iguana" height="100" image={imageCompany} />
        <CardContent>
          <Typography gutterBottom variant="h8" component="div">
            {insuranceCompanyName ?? "Lista de documentos vacíos"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                Número:
                <MDTypography
                  ml={1}
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {insuranceNumber}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                Patente:
                <MDTypography
                  ml={1}
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {licence && licence !== "null" ? licence : "Sin datos"}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                Estado:
                <MDTypography
                  ml={1}
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {status}
                </MDTypography>
              </MDTypography>
            </MDBox>

            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                Fecha de inicio:
                <MDTypography ml={1} variant="caption" fontWeight="medium">
                  {moment(start).format("DD/MM/YYYY-HH:MM")}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                Fecha de expiración:
                <MDTypography ml={1} variant="caption" fontWeight="medium">
                  {moment(expire).format("DD/MM/YYYY-HH:MM")}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </Typography>
        </CardContent>
      </Card>
    </MDBox>
  );
}

// Setting default values for the props of Bill
VehicleInsurances.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
VehicleInsurances.propTypes = {
  insuranceCompanyName: PropTypes.string.isRequired,
  insuranceNumber: PropTypes.string.isRequired,
  imageCompany: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  licence: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  expire: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default VehicleInsurances;
