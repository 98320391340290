import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import moment from "moment";

/* eslint-disable jsx-a11y/label-has-associated-control */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// MS
import service from "../../services/apiMS/index";

import validator from "./validateInsurance";

const initialValues = {
  insurance_id: "",
  insurance_company_name: "",
  insurance_name: "",
  insurance_number: "",
  insurance_status: "",
  insurance_created: "",
  insurance_start: "",
  insurance_expire: "",
  insurance_vehicle_brand: "",
  insurance_vehicle_model: "",
  insurance_vehicle_year: "",
  insurance_vehicle_chasis: "",
};

function FormInsurance() {
  const { insuranceId } = useParams();
  const navigate = useNavigate();
  const [saveResponse, setSaveResponse] = useState(null);

  const { handleSubmit, handleChange, values, resetForm, errors } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const saveInsuranceResponse = await service.saveInsurance({ userData, formValues });

      if (saveInsuranceResponse.ok) {
        setSaveResponse(true);
      }
    },
    validate: (v) => validator(v),
  });

  useEffect(() => {
    const getInsurance = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const insuranceResponse = await service.getInsurance({ userData, insuranceId });

      if (!insuranceResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (insuranceResponse.ok) {
        const insuranceInfoData = await insuranceResponse.json();
        resetForm({
          values: {
            insurance_id: insuranceInfoData.id,
            insurance_company_name: insuranceInfoData.insurance_company.name,
            insurance_name: insuranceInfoData.name,
            insurance_number: insuranceInfoData.number,
            insurance_status: insuranceInfoData.status,
            insurance_created: moment(insuranceInfoData.created_at).format("DD/MM/YYYY"),
            insurance_start: insuranceInfoData.start_at
              ? moment(insuranceInfoData.start_at).format("DD/MM/YYYY")
              : "Sin datos",
            insurance_expire: insuranceInfoData.expire_at
              ? moment(insuranceInfoData.expire_at).format("DD/MM/YYYY")
              : "Sin datos",
            insurance_vehicle_brand: insuranceInfoData.vehicle?.brand ?? "Sin datos",
            insurance_vehicle_model: insuranceInfoData.vehicle?.model ?? "Sin datos",
            insurance_vehicle_year: insuranceInfoData.vehicle?.year ?? "Sin datos",
            insurance_vehicle_chasis: insuranceInfoData.vehicle?.chassis_number ?? "Sin datos",
          },
        });
      }
    };

    getInsurance();
  }, [navigate, resetForm, insuranceId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Editar póliza
            </MDTypography>
          </MDBox>
          <form action="" className="form-container" onSubmit={handleSubmit}>
            {saveResponse ? <Alert severity="success">Póliza actualizada</Alert> : ""}
            <MDBox mt={4} mb={4} mx={6} pt={3} py={3} px={2}>
              <Grid container spacing={4} display="flex" flexDirection="row">
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Aseguradora"
                    variant="standard"
                    id="insurance_company_name"
                    name="insurance_company_name"
                    value={values.insurance_company_name}
                    onChange={handleChange}
                    error={errors.insurance_company_name}
                    helperText={errors.insurance_company_name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Nombre"
                    variant="standard"
                    id="insurance_name"
                    name="insurance_name"
                    value={values.insurance_name}
                    onChange={handleChange}
                    error={errors.insurance_name}
                    helperText={errors.insurance_name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Número"
                    variant="standard"
                    id="insurance_number"
                    name="insurance_number"
                    value={values.insurance_number}
                    onChange={handleChange}
                    error={errors.insurance_number}
                    helperText={errors.insurance_number}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Creación"
                    variant="standard"
                    id="insurance_created"
                    name="insurance_created"
                    value={values.insurance_created}
                    onChange={handleChange}
                    error={errors.insurance_created}
                    helperText={errors.insurance_created}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Inicio"
                    variant="standard"
                    id="insurance_start"
                    name="insurance_start"
                    value={values.insurance_start}
                    onChange={handleChange}
                    error={errors.insurance_start}
                    helperText={errors.insurance_start}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Fin"
                    variant="standard"
                    id="insurance_expire"
                    name="insurance_expire"
                    value={values.insurance_expire}
                    onChange={handleChange}
                    error={errors.insurance_expire}
                    helperText={errors.insurance_expire}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Estado"
                    variant="standard"
                    id="insurance_status"
                    name="insurance_status"
                    value={values.insurance_status}
                    onChange={handleChange}
                    error={errors.insurance_status}
                    helperText={errors.insurance_status}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <MDBox mt={4} mb={6} pt={3} py={3}>
                <Grid container spacing={4} display="flex" flexDirection="row">
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      label="Marca"
                      variant="standard"
                      id="insurance_vehicle_brand"
                      name="insurance_vehicle_brand"
                      value={values.insurance_vehicle_brand}
                      onChange={handleChange}
                      error={errors.insurance_vehicle_brand}
                      helperText={errors.insurance_vehicle_brand}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      label="Modelo"
                      variant="standard"
                      id="insurance_vehicle_model"
                      name="insurance_vehicle_model"
                      value={values.insurance_vehicle_model}
                      onChange={handleChange}
                      error={errors.insurance_vehicle_model}
                      helperText={errors.insurance_vehicle_model}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      label="Año"
                      variant="standard"
                      id="insurance_vehicle_year"
                      name="insurance_vehicle_year"
                      value={values.insurance_vehicle_year}
                      onChange={handleChange}
                      error={errors.insurance_vehicle_year}
                      helperText={errors.insurance_vehicle_year}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      label="Nro. Chasis"
                      variant="standard"
                      id="insurance_vehicle_chasis"
                      name="insurance_vehicle_chasis"
                      value={values.insurance_vehicle_chasis}
                      onChange={handleChange}
                      error={errors.insurance_vehicle_chasis}
                      helperText={errors.insurance_vehicle_chasis}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={6} display="flex" flexDirection="row-reverse">
                <Stack direction="row" spacing={2}>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="dark"
                    onClick={() => navigate(`/insurance/${values.insurance_id}`)}
                  >
                    <Icon>undo</Icon>&nbsp; volver
                  </MDButton>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="success"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="dark"
                    onSubmit={handleSubmit}
                  >
                    <Icon>save</Icon>&nbsp; guardar
                  </MDButton>
                </Stack>
              </MDBox>
            </MDBox>
          </form>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default FormInsurance;
