import MDTypography from "components/MDTypography";

// @mui material components
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Link from "@mui/material/Link";

import moment from "moment";

// eslint-disable-next-line no-unused-vars
export default function data(policyData) {
  return {
    columns: [
      { Header: "poliza_id", accessor: "id", align: "left" },
      { Header: "usuario", accessor: "user", align: "left" },
      { Header: "documento", accessor: "document_number", align: "left" },
      { Header: "patente", accessor: "licence_plate", align: "left" },
      { Header: "marca", accessor: "brand", align: "left" },
      { Header: "póliza", accessor: "name", align: "left" },
      { Header: "estado", accessor: "status_policy", align: "center" },
      { Header: "creación", accessor: "created_at", align: "center" },
      { Header: "inicio", accessor: "start_at", align: "center" },
      { Header: "fin", accessor: "expire_at", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    // eslint-disable-next-line no-shadow, no-unused-vars
    rows: policyData.map((data) => ({
      id: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.id}
        </MDTypography>
      ),
      user: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.user?.first_name} {data.user?.last_name ?? "Sin datos"}
        </MDTypography>
      ),
      document_number: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.user?.document_number ?? "Sin datos"}
        </MDTypography>
      ),
      licence_plate: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.vehicle?.licence_plate ?? "Sin datos"}
        </MDTypography>
      ),
      brand: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.vehicle?.brand ?? "Sin datos"}
        </MDTypography>
      ),
      insurance_company: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.insurance_company?.name ?? "Sin datos"}
        </MDTypography>
      ),
      name: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.name}
        </MDTypography>
      ),
      status_policy: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {data.status}
        </MDTypography>
      ),
      created_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.created_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      start_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.start_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      expire_at: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(data.expire_at).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      action: (
        <Link href={`insurance/${data.id}`}>
          <ChevronRightIcon color="action" fontSize="medium" />
        </Link>
      ),
    })),
  };
}
